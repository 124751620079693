import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import CardModal from '../layouts/CardModal';
import {Link, useNavigate} from 'react-router-dom';
import {useCollectionsQuery} from '@/hooks';
import {shortAddress} from '@/utils/utils';
import './style.scss';

Explore.propTypes = {
  data: PropTypes.array,
};
const imagePerRow = 4;

function Explore(props) {
  // const {data} = props;
  const [next, setNext] = useState(imagePerRow*2);
  const handleMoreImage = () => {
      setNext(next + imagePerRow);
    };
  const [modalShow, setModalShow] = useState(false);

  const [dataTab] = useState([
    {
      id: 1,
      title: 'TRENDING',
      item: 2,
    },
    {
      id: 2,
      title: 'POPULAR',
      item: 4,
    },
  ]);

  const navigate = useNavigate();
  const {data} = useCollectionsQuery({});

  const collectionList = data?.map((item) => {
    const objectData = JSON.parse(item?.data);

    return {...item, ...objectData, authority: shortAddress(item.authority), name: item?.name?.replaceAll('_', ' ')};
  })?.filter(el => el.nftIds?.length > 0);

  return (
    <section className="tf-section tf-explore tf-filter tf-center explore-block">
      <div className="tf-container">
        <div className="row ">
          <div className="col-md-12">
            <div className="tf-heading style-2 wow fadeInUp">
              <h4 className="heading">Explore Collections</h4>
            </div>
          </div>
          <div className="col-md-12">
            <Tabs>
              {/* <TabList>
                {dataTab.map((idx) => (
                  <Tab key={idx.id}>{idx.title}</Tab>
                ))}
              </TabList> */}

              {dataTab.map((idx) => (
                <TabPanel key={idx.id}>
                  <div className="row tf-filter-container wow fadeInUp mt-5">
                    {collectionList?.slice(0, next)?.map((idx) => (
                      <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d pixel">
                        <div className="sc-product style3" onClick={() => navigate(`/collection/${idx?.collectionId}`)}>
                          <div className="features">
                            <div
                              //  className="product-media"
                              style={{width: '330px', height: '290px'}}
                            >
                              <img
                                src={idx.featuredImg}
                                alt="images"
                                style={{width: '100%', height: '100%', objectFit: 'cover'}}
                              />
                            </div>
                          </div>
                          <div className="content">
                            <div className="details-product">
                              <div className="title">
                                <Link to="/item-details">{idx.name}</Link>
                              </div>
                              <div className="creator">
                                <Link to="#">{idx.authority}</Link>
                              </div>
                            </div>
                            {/* <div className="price" style={{minWidth: '140px'}}>
                              <div className="subtitle">Total Volume</div>
                              <div className="cash d-flex align-items-center justify-content-end">
                                99,999.88 <SUISvg color="white" />
                              </div>
                            </div> */}
                            <div className="profile-author">
                              <div className="rounded-circle">
                                <img
                                  style={{width: '36px', height: '36px'}}
                                  src={idx.avatarImg}
                                  alt="avatarImg"
                                  className="avatar rounded-circle border-white border-1"
                                />
                              </div>
                              {/* <Link to="#" className="avatar" data-tooltip="Creator: Daniel Rose" tabIndex="0">
                                <img src={idx.avt2} alt="images" />
                              </Link>
                              <Link to="#" className="avatar" data-tooltip="Creator: Solvador" tabIndex="0">
                                <img src={idx.avt3} alt="images" />
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
          {next < collectionList?.length &&<div className="col-md-12">
            <div className="btn-loadmore mt8 wow fadeInUp">
              <div onClick={handleMoreImage} className="tf-button loadmore style-4">
                Load more
              </div>
            </div>
          </div>}
        </div>
      </div>

      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
}

export default Explore;

const SUISvg = ({color}) => (
  <svg fill="currentColor" viewBox="0 0 26 40" style={{color: '#9448F0', height: '14px', width: '14px'}}>
    <path
      style={{fill: color, fillRule: 'evenodd'}}
      d="M1.8611,33.0541a13.6477,13.6477,0,0,0,23.7778,0,13.89,13.89,0,0,0,0-13.8909L15.1824.8368a1.6444,1.6444,0,0,0-2.8648,0L1.8611,19.1632A13.89,13.89,0,0,0,1.8611,33.0541ZM10.8044,9.5555,13.0338,5.648a.8222.8222,0,0,1,1.4324,0L23.043,20.68a10.8426,10.8426,0,0,1,.8873,8.8828,9.4254,9.4254,0,0,0-.4388-1.4586c-1.1847-3.0254-3.8634-5.36-7.9633-6.9393-2.8187-1.0819-4.618-2.6731-5.3491-4.73C9.2375,13.7848,10.221,10.8942,10.8044,9.5555ZM7.0028,16.2184,4.457,20.68a10.8569,10.8569,0,0,0,0,10.8582,10.6776,10.6776,0,0,0,16.1566,2.935,7.5061,7.5061,0,0,0,.0667-5.2913c-.87-2.1858-2.9646-3.9308-6.2252-5.1876-3.6857-1.4147-6.08-3.6233-7.1157-6.5625A9.297,9.297,0,0,1,7.0028,16.2184Z"
    />
  </svg>
);
