import {message} from 'antd';
import {useRecoilState} from 'recoil';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import API from '@/hooks/api/useCallAPI';

type FetcherParamsT = {id: string; data: any};

const fetcher = async (params: FetcherParamsT) => {
  const rs = await API.patch(`/nfts/${params?.id}`, params?.data);
  return rs.data;
};

const useUpdateNFTMutation = (
  onSuccess?: ((arg0: any, arg1?: any) => void) | undefined,
  onError?: ((arg0: any, arg1?: any) => void) | undefined
) => {
  const queryClient = useQueryClient();

  const fn = useMutation(['conllection', 'update'], {
    mutationFn: (variables: FetcherParamsT) => {
      return fetcher({
        ...variables,
      });
    },
    onSuccess: (rs, vars) => {
      onSuccess && onSuccess(rs, vars);
    },
    onError: (err: any) => {
      message.error(err);
    },
    retry: false,
  });
  return {
    ...fn,
  };
};

export {useUpdateNFTMutation};
