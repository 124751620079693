import React, {createContext, useContext, useState, useMemo, ReactNode, useEffect, FC, useCallback} from 'react';
import {
  JsonRpcProvider,
  SplitCoinTransaction,
  RpcTxnDataSerializer,
  SignerWithProvider,
  Coin,
  MoveCallTransaction,
} from '@mysten/sui.js';
import {toast} from 'react-toastify';
import {
  accountAggregateBalancesSelector,
  accountCoinsSelector,
  convertBalance,
  fetchAllOwnedAndRequiredObjects,
  getID,
  getObjectById,
  ownedObjects,
  selectCoinSetWithCombinedBalanceGreaterThanOrEqual,
  selectCoinWithBalanceEqual,
  selectCoinWithBalanceGreaterThanOrEqual,
} from '@/utils/SuiObject';
import {convertu64, getTimeSecond, useLocalStorageState} from '@/utils/utils';
import {useWallet} from '@suiet/wallet-kit';

import {
  DRA_FOR_SALE,
  DRA_STAKING_POOL_ADDRESS,
  PACKAGE_STAKING_ID,
  DRA_SYSTEM_STATE,
  DRA_TOKEN,
  SuiObjectName,
  GAS_BUDGET_MAX,
  GAS_BUDGET_MIN,
} from '@/utils/constant';
import {message} from 'antd';

import {NftClient} from '../utils';
import {
  client,
  //keypair,
  //signer,
  //signer2,
  COLLECTION_MANAGER_ID,
  COLLECTION_ID,
  COLLECTION_ID2,
  PACKAGE_OBJECT_ID,
  MARKETPLACE_ID,
  PAID_COIN_ID,
  PAID_COIN_ID2,
} from '../utils/constant';
import {strToByteArray} from '../utils/utils';

interface AppBlockchainContext {}
const BlockchainContext = createContext<any>({});

export function useBlockchainContext() {
  return useContext(BlockchainContext);
}

export const UIConsumer = BlockchainContext.Consumer;

export function AppBlockchainProvider({children}: any) {
  const [addressWallet, setAddressWallet] = useState('');
  const [balanceWallet, setBalanceWallet] = useState({});
  const [systemObject, setSystemObject] = useState({});
  const [saleObject, setSaleObject] = useState({});
  const [stakeObjects, setStakeObjects] = useState([]);
  const endpoint = process.env.REACT_APP_SUI_ENDPOINT;
  const serializer = new RpcTxnDataSerializer(endpoint);

  const {connected, getAccounts, signAndExecuteTransaction, account} = useWallet();

  const getAllObjectOfAddress = async (address: string) => {
    const allObjects = await fetchAllOwnedAndRequiredObjects(address);
    const allSuiObjects = ownedObjects(allObjects, address);
    return allSuiObjects;
  };

  const getObjectDRASystem = async () => {
    const res = await getObjectById(DRA_SYSTEM_STATE);
    setSystemObject(res?.data);
  };

  const getObjectDRASSale = async () => {
    const res = await getObjectById(DRA_FOR_SALE);
    setSaleObject(res?.data);
  };

  useEffect(() => {
    if (connected) {
      getBalanceWalet(account?.address);
      getObjectDRASystem();
      getObjectDRASSale();
    }
  }, [connected]);

  useEffect(() => {
    getObjectDRASystem();
    const interval = setInterval(() => {
      getObjectDRASystem();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const getBalanceWalet = async (acc) => {
    setAddressWallet(acc);
    console.log('acc', acc);

    const coins = await getAllCoinsObjects(acc);
    const balances = await accountAggregateBalancesSelector(coins);
    setBalanceWallet(balances);
    getStakesObjects(acc);
  };

  const getAllCoinsObjects = async (address) => {
    const allSuiObjects = await getAllObjectOfAddress(address);
    const coins = accountCoinsSelector(allSuiObjects);

    return coins;
  };

  const getAllStakeObjects = async (address) => {
    const allSuiObjects = await getAllObjectOfAddress(address);
    const coins = accountCoinsSelector(allSuiObjects);
    console.log(allSuiObjects);
    return coins;
  };

  const getCoinsObjectsByType = async (address) => {
    const coins = await getAllCoinsObjects(address);
    return coins.filter((el) => Coin.getCoinTypeArg(el) === SuiObjectName.DRAToken);
  };

  const getSuiObjectsByType = async (address) => {
    const coins = await getAllCoinsObjects(address);
    return coins.filter((el) => Coin.getCoinTypeArg(el) === SuiObjectName.SuiToken);
  };

  const isWhiteList = async (address): Promise<boolean> => {
    // DRA_FOR_SALE
    const res: any = await getObjectById(DRA_FOR_SALE);
    const listUsers = res?.data?.fields?.og_phases[0]?.fields?.list_users;
    const index = listUsers.indexOf(address);
    console.log({
      debug: {
        address: address,
        res: res,
        list_users: listUsers,
        index: index,
      },
    });
    return listUsers.indexOf(address) > -1;
  };

  const getStakesObjects = async (address) => {
    const allSuiObjects = await getAllObjectOfAddress(address);

    const objects = allSuiObjects.filter((el) => el.data?.type === SuiObjectName.DRAStake).map((el) => el.data);
    console.log(objects);

    setStakeObjects(objects);
  };

  const checkFeeTransactions = (fee = 100000) => {
    if(balanceWallet[SuiObjectName.SuiToken] > fee) {
      return true
    }
    message.error('SUI is not enough!');
    return false
  }

  const executeMoveCallDraTokenModule = async (moduleName, methodName: string, arg?: any) => {
    try {
      console.log(arg, 'argargargarg');
      
      const transaction: MoveCallTransaction = {
        packageObjectId: PACKAGE_STAKING_ID,
        module: moduleName,
        function: methodName,
        typeArguments: [],
        arguments: arg,
        gasBudget: 10000,
      };

      const result = await signAndExecuteTransaction({
        transaction: {
          kind: 'moveCall',
          data: transaction,
        },
      });
      getBalanceWalet(addressWallet);
      getStakesObjects(addressWallet);
      getObjectDRASystem();
      return result;
    } catch (err: any) {
      console.log(err);
      message.error(err?.message || 'Transaction failed!');
      throw err;
    }
  };
  const splitModule = (module: string) => {
    return module.split('::').map((el) => el.trim());
  };

  const executeMoveCallNFTModule = async (moduleName, methodName: string, arg?: any) => {
    const [objectId, module] = splitModule(moduleName);
    try {
      const transaction: MoveCallTransaction = {
        packageObjectId: objectId,
        module: module,
        function: methodName,
        typeArguments: [],
        arguments: arg,
        gasBudget: 10000,
      };

      const result = await signAndExecuteTransaction({
        transaction: {
          kind: 'moveCall',
          data: transaction,
        },
      });

      return result;
    } catch (err: any) {
      console.log(err);
      message.error(err?.message || 'Transaction failed!');
      throw err;
    }
  };

  const getTotalStaked = () => {};

  const splitCoin = async (amounts) => {
    const coinsObjectId = await getCoinsObjectsByType(addressWallet);

    const coinObject = selectCoinWithBalanceGreaterThanOrEqual(coinsObjectId, amounts[0], []);
    if (Coin.getBalance(coinObject) !== amounts[0]) {
      const transaction: SplitCoinTransaction = {
        coinObjectId: getID(coinObject),
        splitAmounts: amounts,
        gasBudget: 3000,
      };

      const res = await signAndExecuteTransaction({
        transaction: {
          kind: 'splitCoin',
          data: transaction,
        },
      });
      return res?.effects?.created[0].reference.objectId;
    } else {
      return getID(coinObject);
    }
  };

  const requestBuyPublicSales = async (dra_amount: number, sui_per_dra: number) => {
    const coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
    console.log({coinsSuiObjectId: coinsSuiObjectId});
    const sui_required = dra_amount * sui_per_dra;
    const coinObject = selectCoinWithBalanceGreaterThanOrEqual(coinsSuiObjectId, BigInt(sui_required), []);
    console.log({
      coinObject: {
        coinObject: coinObject,
        coinsSuiObjectId: coinsSuiObjectId,
        sui_required: sui_required,
      },
    });

    const res = await executeMoveCallDraTokenModule('dra_system', 'request_buy_public_sales', [
      DRA_SYSTEM_STATE,
      getID(coinObject),
      dra_amount * Math.pow(10, 9),
      getTimeSecond(),
    ]);

    message.success('Buy DRA added successfully');
  };

  const requestBuyPublicSalesV1 = async (dra_amount: number, sui_per_dra: number) => {
    let coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
    console.log({coinsSuiObjectId: coinsSuiObjectId});
    const sui_required = dra_amount * sui_per_dra;

    let gasObject = selectCoinWithBalanceGreaterThanOrEqual(coinsSuiObjectId, BigInt(GAS_BUDGET_MAX * 2), []);
    console.log({data_checked: gasObject});
    if (gasObject) {
      const coinObject = selectCoinWithBalanceGreaterThanOrEqual(coinsSuiObjectId, BigInt(sui_required), []);
      console.log({
        coinObject: {
          coinObject: coinObject,
          coinsSuiObjectId: coinsSuiObjectId,
          sui_required: sui_required,
        },
      });
      const transaction1: SplitCoinTransaction = {
        coinObjectId: getID(gasObject),
        splitAmounts: [GAS_BUDGET_MAX],
        gasBudget: GAS_BUDGET_MIN,
      };
      const res1 = await signAndExecuteTransaction({
        transaction: {
          kind: 'splitCoin',
          data: transaction1,
        },
      });
      let gasObjectId = res1?.effects?.created[0].reference.objectId;

      coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
      let listMerging = coinsSuiObjectId.filter((el) => Coin.getID(el) != gasObjectId);

      // Check if sui is enough:
      let totalSuiBalance = await accountAggregateBalancesSelector(coinsSuiObjectId);
      let firstObject = getID(listMerging[0]);
      console.log({
        data_checked: {
          firstObject: firstObject,
          listMerging: listMerging,
          totalSuiBalance: totalSuiBalance,
        },
      });

      if (firstObject && totalSuiBalance[SuiObjectName.SuiToken] >= sui_required) {
        let otherObjects = listMerging.filter((el) => Coin.getID(el) != firstObject);
        let objList = otherObjects ? otherObjects.map((el) => getID(el)) : [];
        const res = await executeMoveCallDraTokenModule('dra_system', 'request_buy_public_sales_v1', [
          DRA_SYSTEM_STATE,
          firstObject,
          objList,
          convertu64(dra_amount * Math.pow(10, 9)),
          getTimeSecond(),
        ]);
        console.log({data_checked: res});
        if (res?.effects?.status?.status == 'success') {
          message.success('Buy DRA successfully');
        } else {
          message.error(`Buy DRA failure. Transactions: ${res?.effects?.transactionDigest}`);
        }
      } else {
        message.error('SUI is not enough!');
      }
    } else {
      message.error('SUI is not enough!');
    }
  };

  const requestBuyPublicAndOgSales = async (dra_amount: number, sui_per_dra: number) => {
    const isForOg: boolean = await isWhiteList(addressWallet);

    let coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
    console.log({coinsSuiObjectId: coinsSuiObjectId});
    const sui_required = dra_amount * sui_per_dra;

    let gasObject = selectCoinWithBalanceGreaterThanOrEqual(coinsSuiObjectId, BigInt(GAS_BUDGET_MAX * 2), []);
    console.log({data_checked: gasObject});
    if (gasObject) {
      const transaction1: SplitCoinTransaction = {
        coinObjectId: getID(gasObject),
        splitAmounts: [GAS_BUDGET_MAX],
        gasBudget: GAS_BUDGET_MIN,
      };
      const res1 = await signAndExecuteTransaction({
        transaction: {
          kind: 'splitCoin',
          data: transaction1,
        },
      });
      let gasObjectId = res1?.effects?.created[0].reference.objectId;

      coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
      let listMerging = coinsSuiObjectId.filter((el) => Coin.getID(el) != gasObjectId);

      // Check if sui is enough:
      let totalSuiBalance = await accountAggregateBalancesSelector(coinsSuiObjectId);
      let firstObject = getID(listMerging[0]);
      console.log({
        data_checked: {
          firstObject: firstObject,
          listMerging: listMerging,
          totalSuiBalance: totalSuiBalance,
        },
      });

      if (firstObject && totalSuiBalance[SuiObjectName.SuiToken] >= sui_required) {
        let otherObjects = listMerging.filter((el) => Coin.getID(el) != firstObject);
        let objList = otherObjects ? otherObjects.map((el) => getID(el)) : [];

        let res;
        if (isForOg) {
          res = await executeMoveCallDraTokenModule('dra_system', 'request_buy_og_sales_v1', [
            DRA_SYSTEM_STATE,
            DRA_FOR_SALE,
            firstObject,
            objList,
            convertu64(dra_amount * Math.pow(10, 9)),
            getTimeSecond(),
          ]);
        } else {
          res = await executeMoveCallDraTokenModule('dra_system', 'request_buy_public_sales_v1', [
            DRA_SYSTEM_STATE,
            DRA_FOR_SALE,
            firstObject,
            objList,
            convertu64(dra_amount * Math.pow(10, 9)),
            getTimeSecond(),
          ]);
        }

        console.log({data_checked: res});
        if (res?.effects?.status?.status == 'success') {
          message.success('Buy DRA successfully');
        } else {
          message.error(`Buy DRA failure. Transactions: ${res?.effects?.transactionDigest}`);
        }
      } else {
        message.error('SUI is not enough!');
      }
    } else {
      message.error('SUI is not enough!');
    }
  };

  const requestBuyPublicOgSales = async (dra_amount: number, sui_per_dra: number) => {
    const isForOg: boolean = await isWhiteList(addressWallet);
    if (!isForOg) {
      message.error('You can not buy on the OG sales!');
      return;
    }

    let coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
    console.log({coinsSuiObjectId: coinsSuiObjectId});
    const sui_required = dra_amount * sui_per_dra;

    let gasObject = selectCoinWithBalanceGreaterThanOrEqual(coinsSuiObjectId, BigInt(GAS_BUDGET_MAX * 2), []);
    if (gasObject) {
      const transaction1: SplitCoinTransaction = {
        coinObjectId: getID(gasObject),
        splitAmounts: [GAS_BUDGET_MAX],
        gasBudget: GAS_BUDGET_MIN,
      };
      const res1 = await signAndExecuteTransaction({
        transaction: {
          kind: 'splitCoin',
          data: transaction1,
        },
      });
      let gasObjectId = res1?.effects?.created[0].reference.objectId;

      coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
      let listMerging = coinsSuiObjectId.filter((el) => Coin.getID(el) != gasObjectId);

      // Check if sui is enough:
      let totalSuiBalance = await accountAggregateBalancesSelector(coinsSuiObjectId);
      let firstObject = getID(listMerging[0]);
      console.log(getTimeSecond(), 'getTimeSecond()');

      if (firstObject && totalSuiBalance[SuiObjectName.SuiToken] >= sui_required) {
        let otherObjects = listMerging.filter((el) => Coin.getID(el) != firstObject);
        let objList = otherObjects ? otherObjects.map((el) => getID(el)) : [];
        const res = await executeMoveCallDraTokenModule('dra_system', 'request_buy_og_sales_v1', [
          DRA_SYSTEM_STATE,
          DRA_FOR_SALE,
          firstObject,
          objList,
          convertu64(dra_amount * Math.pow(10, 9)),
          convertu64(getTimeSecond()),
        ]);
        console.log({data_checked: res});
        if (res?.effects?.status?.status == 'success') {
          message.success('Buy DRA successfully');
          getObjectDRASSale();
        } else {
          message.error(`Buy DRA failure. Transactions: ${res?.effects?.transactionDigest}`);
        }
      } else {
        message.error('SUI is not enough!');
      }
    } else {
      message.error('SUI is not enough!');
    }

    // const coinObject = selectCoinWithBalanceGreaterThanOrEqual(coinsSuiObjectId, BigInt(0), []);
    // console.log({
    //   coinObject: {
    //     coinObject: coinObject,
    //     coinsSuiObjectId: coinsSuiObjectId,
    //     sui_required: sui_required,
    //   },
    // });
    //
    // if (coinObject) {
    //   const objList = coinObject.map((el) => getID(el));
    //   const res = await executeMoveCallDraTokenModule('dra_system', 'request_buy_og_sales_v1', [
    //     DRA_SYSTEM_STATE,
    //     DRA_FOR_SALE,
    //     suiCoinBase,
    //     objList,
    //     dra_amount * Math.pow(10, 9),
    //     getTimeSecond(),
    //   ]);
    //
    //   message.success('Buy DRA added successfully');
    // }
  };

  const requestAddStake = async (amount) => {
    const coinsObjectId = await getCoinsObjectsByType(addressWallet);
    const newAmount = BigInt(amount).toString()
    console.log(newAmount, 'newAmount');
    
    const coinObject = selectCoinSetWithCombinedBalanceGreaterThanOrEqual(coinsObjectId, amount, []);
    const objList = coinObject.map((el) => getID(el));
    if (stakeObjects.length) {
      const res = await executeMoveCallDraTokenModule('dra_system', 'request_add_stake_next_v1', [
        DRA_SYSTEM_STATE,
        getID(stakeObjects[0]),
        objList,
        DRA_STAKING_POOL_ADDRESS,
        convertu64(amount),
        getTimeSecond(),
      ]);
      console.log('res1', res);
    } else {
      const res = await executeMoveCallDraTokenModule('dra_system', 'request_add_stake_first_v1', [
        DRA_SYSTEM_STATE,
        objList,
        DRA_STAKING_POOL_ADDRESS,
        convertu64(amount),
        getTimeSecond(),
      ]);
      console.log('res2', res);
    }
    message.success('Stake added successfully');
  };

  const requestClaimRw = async (amount) => {
    if (stakeObjects.length > 0) {
      const res = await executeMoveCallDraTokenModule('dra_system', 'request_claim_rewards', [
        DRA_SYSTEM_STATE,
        DRA_STAKING_POOL_ADDRESS,
        getID(stakeObjects[0]),
        getTimeSecond(),
      ]);
      message.success('Claim reward successfully');
    }
  };


  const unStakeDra = async (amount) => {
    if (amount > 0 && stakeObjects.length > 0) {
      const res = await executeMoveCallDraTokenModule('dra_system', 'request_withdraw_stake', [
        DRA_SYSTEM_STATE,
        DRA_STAKING_POOL_ADDRESS,
        getID(stakeObjects[0]),
        convertu64(amount),
        // stakeObjects[0].fields.total_staked,
        getTimeSecond(),
      ]);
      message.success('Unstake successfully');
      getStakesObjects(addressWallet);
    }
  };
  //-------------------------------------------
  // Collection func
  const modifiedID = (id: string) => {
    let new_id = id;

    while (new_id.length < 42) {
      new_id = new_id.replace('x', 'x0');
    }

    return new_id;
  };

  // Read Market object
  const getMarketplace = async () => {
    let marketplace = await client.getMarketplaceById(MARKETPLACE_ID);
    if (marketplace) {
      console.log('marketplace = ', marketplace);
      console.log('listingByCollection = ', marketplace.listingByCollection[COLLECTION_ID]);
    }
  };

  // Read collectionManager object
  const getCollectionManager = async () => {
    let collectionManager = await client.getCollectionManager(COLLECTION_MANAGER_ID);
    if (collectionManager) {
      console.log('collectionManager = ', collectionManager);
    }
  };

  // Read collection objects
  const getCollections = async () => {
    const collections = await client.getCollectionList(COLLECTION_MANAGER_ID);
    //console.log("collections = ", collections);
    const collectionsInAPackage = collections.filter((_) => modifiedID(_.packageObjectId) === PACKAGE_OBJECT_ID);
    console.log('collectionsInAPackage = ', collectionsInAPackage);
  };

  // Read nft objects
  const getNfts = async () => {
    let accs = await getAccounts();

    //const nfts = await client.getNftsForAddress(`0x${keypair.getPublicKey().toSuiAddress()}`);
    const nfts = await client.getNftsForAddress(account.address);

    const nftsList = nfts.filter(
      (_) => modifiedID(_.packageObjectId) === PACKAGE_OBJECT_ID /*&& _.collectionId === COLLECTION_ID*/
    );
    console.log('nftsInAPackageForAddress = ', nftsList);
  };

  // A move call to create collection (create_collection func)
  const createCollection = async () => {
    let createCollectionTransaction;
    let txResult;

    // House collection
    createCollectionTransaction = NftClient.buildCreateCollectionWalletTx({
      packageObjectId: PACKAGE_OBJECT_ID,
      moduleName: 'collection',
      collectionManager: COLLECTION_MANAGER_ID,
      name: 'House',
      description: 'Collection of beautiful houses',
      symbol: 'HSE',
      tags: ['House', 'Architecture'],
      isMutable: true,
      royaltyFee: 500,
    });
    txResult = await signAndExecuteTransaction(createCollectionTransaction);
    console.log('createCollectionTxResult', txResult);
    //----------------------------------------------------------------------

    // Ship collection
    createCollectionTransaction = NftClient.buildCreateCollectionWalletTx({
      packageObjectId: PACKAGE_OBJECT_ID,
      moduleName: 'collection',
      collectionManager: COLLECTION_MANAGER_ID,
      name: 'Ship',
      description: 'Collection of ships',
      symbol: 'SHIP',
      tags: ['Ship', 'Luxury cruise', 'Vacation', 'We belong to the sea'],
      isMutable: true,
      royaltyFee: 234,
    });

    txResult = await signAndExecuteTransaction(createCollectionTransaction);
    console.log('createCollectionTxResult', txResult);
  };

  const checkAndSplitSuiFee = async () => {
    let coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
    console.log({coinsSuiObjectId: coinsSuiObjectId});

    let gasObject = selectCoinWithBalanceGreaterThanOrEqual(coinsSuiObjectId, BigInt(GAS_BUDGET_MAX * 2), []);
    console.log({data_checked: gasObject});
    if (gasObject) {
      const transaction1: SplitCoinTransaction = {
        coinObjectId: getID(gasObject),
        splitAmounts: [GAS_BUDGET_MAX],
        gasBudget: GAS_BUDGET_MIN,
      };
      const res1 = await signAndExecuteTransaction({
        transaction: {
          kind: 'splitCoin',
          data: transaction1,
        },
      });
      let gasObjectId = res1?.effects?.created[0].reference.objectId;

      coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
      let listMerging = coinsSuiObjectId.filter((el) => Coin.getID(el) != gasObjectId);

      // Check if sui is enough:
      let firstObject = getID(listMerging[0]);

      if (firstObject) {
        return true;
      } else {
        message.error('SUI is not enough!');
      }
    } else {
      message.error('SUI is not enough!');
    }
    return false;
  };

  // A move call to mint Nft (mint_to_sender func)
  const mintNft = async (argument, collection) => {
    const res = await executeMoveCallNFTModule(
      `${collection.collectionId}::${collection.name}`,
      'mint_nft',
      argument
    );
    if (res?.effects?.status?.status == 'success') {
      return res;
    } else {
      message.error(`Create NFT failed. Transactions: ${res?.effects?.transactionDigest}`);
      throw false;
    }
  };

  // A move call to list Nft to marketplace (list func)
  const listNft = async () => {
    let listTransaction;
    let txResult;

    // Lists "Villa" NFT in house collection
    let item = '0xd7a2e582effb71b839b224fcd855010044b72d6d';

    listTransaction = NftClient.buildListWalletTx({
      packageObjectId: PACKAGE_OBJECT_ID,
      moduleName: 'marketplace',
      marketplace: MARKETPLACE_ID,
      item: item,
      price: 120,
    });

    txResult = await signAndExecuteTransaction(listTransaction);
    console.log('listNftTxResult', txResult);
  };

  // A move call to delist Nft (delist_and_take func)
  const delistNft = async () => {
    let delistAndTakeTransaction;
    let txResult;

    // Delist "Villa" NFT in house collection
    let itemId = '0xd7a2e582effb71b839b224fcd855010044b72d6d';

    delistAndTakeTransaction = NftClient.buildDelistAndTakeWalletTx({
      packageObjectId: PACKAGE_OBJECT_ID,
      moduleName: 'marketplace',
      marketplace: MARKETPLACE_ID,
      itemId: itemId,
    });

    txResult = await signAndExecuteTransaction(delistAndTakeTransaction);
    console.log('delistNftTxResult', txResult);
  };

  // A move call to purchase Nft (purchase_and_take_mut func)
  const purchaseNft = async () => {
    let purchaseAndTakeMutTransaction;
    let txResult;

    // Buy "Villa" NFT in house collection
    let itemId = '0xd7a2e582effb71b839b224fcd855010044b72d6d';

    purchaseAndTakeMutTransaction = NftClient.buildPurchaseAndTakeMutWalletTx({
      packageObjectId: PACKAGE_OBJECT_ID,
      moduleName: 'marketplace',
      marketplace: MARKETPLACE_ID,
      itemId: itemId,
      collection: COLLECTION_ID,
      paid: PAID_COIN_ID,
    });

    txResult = await signAndExecuteTransaction(purchaseAndTakeMutTransaction);
    console.log('purchaseNftTxResult', txResult);
  };

  const value = {
    connected,
    getTotalStaked,
    balanceWallet,
    addressWallet,
    splitCoin,
    requestBuyPublicAndOgSales,
    requestAddStake,
    stakeObjects,
    requestClaimRw,
    getMarketplace,
    getCollectionManager,
    getCollections,
    getNfts,
    createCollection,
    mintNft,
    listNft,
    delistNft,
    purchaseNft,
    systemObject,
    getTimeSecond,
    unStakeDra,
    executeMoveCallNFTModule,
    saleObject,
    isWhiteList,
    requestBuyPublicSalesV1,
    requestBuyPublicOgSales,
    getAllObjectOfAddress
  };

  return (
    <BlockchainContext.Provider value={value}>
      <div className="app-element">{children}</div>
    </BlockchainContext.Provider>
  );
}

export const withContext = (Component: FC) => {
  return (props: any) => {
    return (
      <BlockchainContext.Consumer>
        {(globalState) => {
          return <Component {...globalState} {...props} />;
        }}
      </BlockchainContext.Consumer>
    );
  };
};
