import slider12 from '../assets/images/slider/slider-12.png';
import slider13 from '../assets/images/slider/slider-13.png';
import slider14 from '../assets/images/slider/slider-14.png';
import slider15 from '../assets/images/slider/slider-15.png';
import StakingBg from '../assets/icon/staking-bg.png'
import balanceIcon from '../assets/icon/balance-icon.png'
import screenIcon from '../assets/icon/screen-icon.png'

import {Link} from 'react-router-dom';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {SuiObjectName} from '@/utils/constant';
import {convertBalance, convertBalanceNumber, convertRewardsBalance} from '@/utils/SuiObject';
import {message} from 'antd';

// const YEARS_TO_SECONDS = 31556926;
const YEARS_TO_SECONDS = 365 * 24 * 60 * 60;
let regexp = /^\d+\.?\d{0,6}$/;

message.config({
  maxCount: 1,
});

function Staking() {
  const {balanceWallet, systemObject, requestAddStake, getTimeSecond, requestClaimRw, stakeObjects, unStakeDra} =
    useBlockchainContext();
  const [draReward, setDraReward] = useState(0);
  const intervalReward = useRef(null);

  const stakeObject = stakeObjects[0];

  const poolInfo = systemObject?.fields?.pools?.fields?.active_pools[0]?.fields;

  const [inputAmount, setInputAmount] = useState('');
  const [inputAmountWithdraw, setInputAmountWithdraw] = useState('');

  useEffect(() => {
    if (intervalReward?.current) {
      clearInterval(intervalReward.current);
    }

    // let dra_rewards =
    //   stakeObject?.fields?.unclaimed_reward / 10 ** 9 +
    //   (stakeObject?.fields?.total_staked *
    //     stakeObject?.fields?.apy *
    //     (getTimeSecond() - stakeObject?.fields?.last_update_time)) /
    //     (100 * YEARS_TO_SECONDS);

    // setDraReward(Math.floor(dra_rewards));

    const interval = setInterval(() => {
      const unclaimedReward = parseInt(stakeObject?.fields?.unclaimed_reward);
      const totalStaked = parseInt(stakeObject?.fields?.total_staked);
      const apyPerSecond = (stakeObject?.fields?.apy * 0.01) / YEARS_TO_SECONDS;
      const stakeTimeAccumulated = getTimeSecond() - stakeObject?.fields?.last_update_time;

      const estimatedReward = unclaimedReward + totalStaked * apyPerSecond * stakeTimeAccumulated;

      // let dra_rewards =
      //   stakeObject?.fields?.unclaimed_reward +
      //   (stakeObject?.fields?.total_staked *
      //     stakeObject?.fields?.apy *
      //     (getTimeSecond() - stakeObject?.fields?.last_update_time)) /
      //     (100 * YEARS_TO_SECONDS);

      intervalReward.current = interval;

      setDraReward(Math.floor(estimatedReward));
    }, 1000);

    return () => clearInterval(interval);
  }, [stakeObject]);

  const isEnableStakeButton = useMemo(() => {
    const amount = Number(inputAmount);

    if (amount > convertBalanceNumber(balanceWallet[SuiObjectName.DRAToken]) || amount <= 0) {
      return false;
    }
    return true;
  }, [inputAmount, balanceWallet]);

  const isEnableUnStakeButton = useMemo(() => {
    const amount = Number(inputAmountWithdraw);

    if (amount > convertBalanceNumber(stakeObject?.fields?.total_staked) || amount <= 0) {
      return false;
    }
    return true;
  }, [inputAmountWithdraw, stakeObject]);

  return (
    <div className='staking-page'>
      {/* <section className="tf-page-title">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Staking</li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <div className='head-section'>
        <img className='w-100 staking-bg' src={StakingBg} alt="bg"/>
        <div className="col-md-12 content-block ">
              <div className="col-md-5 mx-auto tf-slider-item style-3">
                <div className="content-inner">
                  <img src={slider12} alt="slider12" className="img-star star-1 ani4" />
                  <img src={slider13} alt="slider13" className="img-star star-2 ani5" />
                  <img src={slider14} alt="slider14" className="img-star star-3 ani4" />
                  <img src={slider15} alt="slider15" className="img-star star-4 ani5" />
                  <h1 className="heading pt-5">Staking</h1>
                  <p className="sub-heading" style={{fontSize: '20px', minHeight: '60px'}}>
                    {/* Staking desc */}  
                  </p>
                </div>
              </div>
              <div className="col-md-9 mx-auto tf-slider-item style-3">
                <div className='balance-block'>
                  <div className='balance-cont'>
                    <img src={balanceIcon} alt="icons"className="icon-balance" />
                    <div>
                      <div className='block-txt'>Total Staked</div>
                      <div className='block-content'>{convertBalance(poolInfo?.pools_dra_staked)} DRA</div>
                    </div>
                  </div>
                  <div className='balance-cont'>
                    <img src={screenIcon} alt="icons"className="icon-balance" />
                    <div>
                      <div className='block-txt'>Estimated Annual Yield</div>
                      <div className='block-content'>{poolInfo?.apy}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>


      <section className="tf-connect-wallet">
        <div className="tf-container">
          <div className="row justify-content-center">

            {/* <div className="col-md-12">
              <div
                className="d-md-flex w-full tf-wallet"
                style={{width: '100%', minHeight: '100px', padding: '20px', cursor: 'auto'}}
              >
                <div style={{width: '100%', textAlign: 'center'}}>
                  <h2 className="title">Total Staked</h2>
                  <h6 className="title" style={{fontWeight: 800, color: '#2fd3dd'}}>
                    {convertBalance(poolInfo?.pools_dra_staked)} DRA
                  </h6>
                </div>

                <div style={{width: '100%', textAlign: 'center'}}>
                  <h2 className="title">Estimated Annual Yield</h2>

                  <div
                    className="icon  d-flex justify-content-center"
                    style={{width: '100%', textAlign: 'center', marginBottom: '10px'}}
                  >
                    <span className="label p-2" style={{fontSize: '32px', color: '#2fd3dd'}}>
                      {poolInfo?.apy}%
                    </span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-12 col-lg-6 col-xl-4">
              <div
                className="tf-wallet"
                style={{minHeight: '200px', border: '1px solid var(--product-color18)', cursor: 'auto'}}
              >
                <h6 className="title">My Balance</h6>
                <p className="content">
                  {balanceWallet[SuiObjectName.DRAToken] ? convertBalance(balanceWallet[SuiObjectName.DRAToken]) : 0}{' '}
                  DRA
                </p>
                <div
                  className="row my-4 mx-auto w-100"
                  style={{
                    maxWidth: '420px',
                    minHeight: '60px',
                  }}
                >
                  <input
                    type="number"
                    aria-required="true"
                    className="rounded-circle"
                    style={{borderRadius: '35px'}}
                    value={inputAmount}
                    onInput={(event) => {
                      if (!event.target.value) {
                        setInputAmount('');
                        return;
                      }
                      if (regexp.test(event.target.value)) setInputAmount(event.target.value);
                    }}
                    placeholder="Enter Amount To Stake"
                  />
                </div>
                <div
                  className="row d-flex justify-content-center mx-auto w-100"
                  style={{
                    maxWidth: '420px',
                  }}
                >
                  <button
                    style={{
                      background: !isEnableStakeButton ? 'grey' : '#3749E9',
                      color: !isEnableStakeButton ? 'darkgrey' : '#fff',
                    }}
                    className="submit w-100"
                    type="submit"
                    onClick={async () => {
                      await requestAddStake(Number(inputAmount) * Math.pow(10, 9));
                      setInputAmount('');
                    }}
                    disabled={!isEnableStakeButton}
                  >
                    Stake
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl-4">
              <div
                className="tf-wallet"
                style={{minHeight: '200px', border: '1px solid var(--product-color18)', cursor: 'auto'}}
              >
                <h6 className="title">My Stake</h6>

                <p className="content">
                  {stakeObject?.fields?.total_staked ? convertBalance(stakeObject?.fields?.total_staked) : 0} DRA
                </p>

                <div
                  className="row my-4 mx-auto w-100"
                  style={{
                    maxWidth: '420px',
                    minHeight: '60px',
                  }}
                >
                  <input
                    type="number"
                    aria-required="true"
                    className="rounded-circle"
                    style={{borderRadius: '35px'}}
                    value={inputAmountWithdraw}
                    onInput={(event) => {
                      if (!event.target.value) {
                        setInputAmountWithdraw('');
                        return;
                      }
                      if (regexp.test(event.target.value)) setInputAmountWithdraw(event.target.value);
                    }}
                    placeholder="Enter Amount To Unstake"
                  />
                </div>

                <div
                  className="row d-flex justify-content-center mx-auto w-100"
                  style={{
                    maxWidth: '420px',
                  }}
                >
                  <button
                    style={{
                      background: !isEnableUnStakeButton ? 'grey' : '#3749E9',
                      color: !isEnableUnStakeButton ? 'darkgrey' : '#fff',
                    }}
                    className="submit w-100"
                    type="submit"
                    onClick={async () => {
                      await unStakeDra(Number(inputAmountWithdraw) * Math.pow(10, 9));
                      setInputAmountWithdraw('');
                    }}
                    disabled={!isEnableUnStakeButton}
                  >
                    Unstake
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl-4">
              <div
                className="tf-wallet"
                style={{minHeight: '200px', border: '1px solid var(--product-color18)', cursor: 'auto'}}
              >
                <h6 className="title">My Rewards</h6>
                <p className="content">{draReward ? convertRewardsBalance(draReward) : 0} DRA</p>
                <div
                  className="row my-4 mx-auto w-100"
                  style={{
                    maxWidth: '420px',
                    minHeight: '60px',
                  }}
                ></div>
                <div
                  className="row d-flex justify-content-center mx-auto w-100"
                  style={{
                    maxWidth: '420px',
                  }}
                >
                  <button
                    disabled={!draReward || draReward <= 0}
                    className="submit w-100"
                    type="submit"
                    onClick={requestClaimRw}
                    style={{
                      background: !draReward || draReward <= 0 ? 'grey' : '#3749E9',
                      color: !draReward || draReward <= 0 ? 'darkgrey' : '#fff',
                    }}
                  >
                    Claim
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Staking;
