import {useQuery} from '@tanstack/react-query';
import {isEmpty} from 'lodash';
import API from '@/hooks/api/useCallAPI';

const fetcher = async (params) => {
  const rs = await API.get(`/users`, {params: {filter: params}});
  return rs.data;
};

const useUserQuery = (params) => {
  const fn = useQuery(
    ['users', params],
    () => {
      return fetcher(params);
    },
    {
      refetchOnWindowFocus: true,
      retry: false,
    }
  );
  return {
    ...fn,
  };
};

export {useUserQuery};
