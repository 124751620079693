import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PageTitle from '@/components/pagetitle/PageTitle';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import img from '@/assets/images/background/thumb-pagetitle.jpg';
import avt from '@/assets/images/author/author-db.jpg';

import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {useCollectionsQuery, useNFTsQuery} from '@/hooks';
import {convertu64, shortAddress} from '@/utils/utils';
import './style.scss';
import {DatePicker, Form, message, Select} from 'antd';
import {UploadImage} from '@/components/UploadImage';
import {useCreateUserMutation} from '@/hooks/user/useCreateUserMutation';
import {useUserQuery} from '@/hooks/user/useUserQuery';
import {convertBalance, convertBalanceNumber} from '@/utils/SuiObject';
import {Dropdown} from 'react-bootstrap';
import {useCreateFarmMutation} from '@/hooks/farms/useCreateNftMutation';
import moment from 'moment';
import {DRA_TOKEN, SuiObjectName} from '@/utils/constant';
import {useNFTFarmContext} from '@/contexts/NFTFarmProtocolContext';

const DAY_IN_SECONDS = 60 * 60 * 24;

function AdminPage(props) {
  const navigate = useNavigate();
  const {addressWallet, balanceWallet} = useBlockchainContext();
  const {data: dataCollections} = useCollectionsQuery({});
  const [searchParams] = useSearchParams();
  const [collectionSelected, setcollectionSelected] = useState({});
  const {data: dataUser, refetch} = useUserQuery({where: {address: addressWallet}});
  const {mutateAsync: createFarm} = useCreateFarmMutation();
  const {createPoolNft} = useNFTFarmContext();

  const {data: nfts} = useNFTsQuery();
  const [form] = Form.useForm();

  const collections = useMemo(
    () =>
      dataCollections
        ?.filter((ele) => !!ele.dataObject?.ret?.collection?.witnessTypeArg && ele?.data.includes('avatarImg'))
        .map((el) => ({...el, ...JSON.parse(el.data)})),
    [dataCollections]
  );
  useEffect(() => {
    if (dataUser?.length) {
      const userInfo = dataUser[0];
      form.setFieldsValue({
        ...userInfo,
        description: userInfo?.dataObject?.description,
        discordUrl: userInfo?.dataObject?.discordUrl,
      });
    }
  }, [dataUser]);
  console.log('balanceWallet', balanceWallet);
  const address = searchParams.get('address');

  //   const getCollectionById = useCallback(
  //     (collectionId) => {
  //       return collections?.find((el) => el.collectionId === collectionId);
  //     },
  //     [collections]
  //   );

  //   const nftByCollection = useMemo(() => {
  //     const nftByCol = {};
  //     nfts?.map((el) => (nftByCol[el.collectionId] = [...(nftByCol?.[el.collectionId] || []), el]));
  //     console.log('nftByCol', nftByCol);
  //     return nftByCol;
  //   }, [addressWallet, nfts]);

  const myCollections = useMemo(
    () => collections?.filter((ele) => ele?.authority === (address || addressWallet)),
    [collections, addressWallet]
  );

  const myNFTs = useMemo(
    () => nfts?.filter((ele) => ele?.nftOwner === (address || addressWallet)),
    [nfts, addressWallet]
  );
  const userInfo = dataUser?.[0];

  const onCreateFarm = async (pool) => {
    console.log(pool, moment(), 'poolpoolpoolpool');
    const res = await createPoolNft(
      collectionSelected,
      pool.tokenReward,
      convertu64(Date.parse(pool.date) / 1000),
      convertu64(Date.parse(pool.date) / 1000 + pool.poolLength * DAY_IN_SECONDS)
    );
    if (res) {
      createFarm({
        nftCollectionId: collectionSelected,
        tokenReward: pool.tokenReward,
        poolLength: Number(pool.poolLength),
        multiplier: Number(pool.multiplier),
        startTime: pool.date.format(),
        ownerFarming: addressWallet,
        dataObject: {nftFarmPool: res?.effects?.created[0].reference.objectId}
      });
      refetch();
    }
  };
  const onChange = (value) => {
    console.log(`selected ${value}`);
    setcollectionSelected(value);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };
  return (
    <div className="admin-page">
      <section className="tf-dashboard tf-tab">
        <div className="tf-container">
          <Tabs className="dashboard-filter">
            <div className="row ">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <div className="dashboard-user">
                  <TabList className="filter-menuu menu-tab">
                    <Tab>
                      <a>
                        {' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{' '}
                        Create NFT Farm
                      </a>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 overflow-table">
                <div className="dashboard-content inventory content-tab">
                  <TabPanel>
                    <div className="inner-content profile">
                      <h4 className="title-dashboard">Create Farm Pool</h4>

                      <Form
                        className="form-edit-profile"
                        initialValues={{poolLength: '30'}}
                        form={form}
                        onFinish={onCreateFarm}
                        autoComplete="off"
                      >
                        <div className="user-profile row">
                          <fieldset className="col-12 col-md-6">
                            <h6>
                              Select NFT Collection<span className="text-red">*</span>
                            </h6>
                            <Form.Item
                              className="form-item"
                              name="collection"
                              rules={[{required: true, message: 'Please input your name!'}]}
                            >
                              <Select
                                showSearch
                                placeholder="Select token"
                                optionFilterProp="children"
                                popupClassName="select-nft-collection"
                                onChange={onChange}
                                onSearch={onSearch}
                                defaultValue=""
                                options={collections?.map((el) => {
                                  return {
                                    label: (
                                      <div>
                                        <img className="img-col" src={el?.avatarImg} />
                                        <span>{el?.name}</span>
                                      </div>
                                    ),
                                    value: el.collectionId,
                                  };
                                })}
                              />
                            </Form.Item>
                          </fieldset>
                          <div className="col-12 col-md-6">
                            <h6>Start Date & Time</h6>
                            <Form.Item
                              className="form-item"
                              name="date"
                              rules={[{required: true, message: 'Please input your Bio!'}]}
                            >
                              <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                              />
                            </Form.Item>
                          </div>
                          <fieldset className="col-12 col-md-6">
                            <h6>Pool Length (days)</h6>
                            <Form.Item
                              className="form-item"
                              name="poolLength"
                              rules={[{required: true, message: 'Please input your Bio!'}]}
                            >
                              <Select
                                defaultValue="30"
                                options={[
                                  {
                                    value: '30',
                                    label: '30 days',
                                  },

                                  {
                                    value: '90',
                                    label: '90 days',
                                  },
                                ]}
                              />
                            </Form.Item>
                          </fieldset>
                          <fieldset className="col-12 col-md-6">
                            <h6>DRA Token Reward</h6>
                            <div>DRA balance: {convertBalance(balanceWallet[SuiObjectName.DRAToken]) || 0}</div>
                            <Form.Item
                              className="form-item"
                              name="tokenReward"
                              rules={[{required: true, message: 'Please input your Bio!'}]}
                            >
                              <input type="text" />
                            </Form.Item>
                          </fieldset>
                          <fieldset className="col-12 col-md-6">
                            <h6>Multiplier</h6>
                            <Form.Item
                              className="form-item"
                              name="multiplier"
                              rules={[{required: true, message: 'Please input your Bio!'}]}
                            >
                              <input type="text" />
                            </Form.Item>
                          </fieldset>
                        </div>
                        <button className="btn-form" type="submit">
                          Create Pool
                        </button>
                      </Form>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </section>
    </div>
  );
}

export default AdminPage;

const SUISvg = ({color}) => (
  <svg fill="currentColor" viewBox="0 0 26 40" style={{color: '#9448F0', height: '14px', width: '14px'}}>
    <path
      style={{fill: color, fillRule: 'evenodd'}}
      d="M1.8611,33.0541a13.6477,13.6477,0,0,0,23.7778,0,13.89,13.89,0,0,0,0-13.8909L15.1824.8368a1.6444,1.6444,0,0,0-2.8648,0L1.8611,19.1632A13.89,13.89,0,0,0,1.8611,33.0541ZM10.8044,9.5555,13.0338,5.648a.8222.8222,0,0,1,1.4324,0L23.043,20.68a10.8426,10.8426,0,0,1,.8873,8.8828,9.4254,9.4254,0,0,0-.4388-1.4586c-1.1847-3.0254-3.8634-5.36-7.9633-6.9393-2.8187-1.0819-4.618-2.6731-5.3491-4.73C9.2375,13.7848,10.221,10.8942,10.8044,9.5555ZM7.0028,16.2184,4.457,20.68a10.8569,10.8569,0,0,0,0,10.8582,10.6776,10.6776,0,0,0,16.1566,2.935,7.5061,7.5061,0,0,0,.0667-5.2913c-.87-2.1858-2.9646-3.9308-6.2252-5.1876-3.6857-1.4147-6.08-3.6233-7.1157-6.5625A9.297,9.297,0,0,1,7.0028,16.2184Z"
    />
  </svg>
);
