import {useEffect} from 'react';
import {message} from 'antd';
import {useResetRecoilState} from 'recoil';
import Axios from 'axios';
import axios from 'axios';

const API = Axios.create({
  timeout: 30000,
  baseURL: process.env.REACT_APP_API_URL || 'https://devnetapi.dragonsui.com',
});

API.interceptors.request.use(function (config) {
  if (config.headers) {
  }
  return config;
});

let isRefreshing = false;
const refreshSubscribers: any = [];

const subscribeTokenRefresh = (cb: any) => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = (token: any) => {
  refreshSubscribers.map((cb: (arg0: any) => any) => cb(token));
};

const AxiosInterceptor = ({children}: any) => {
  return children;
};

export default API;
export {AxiosInterceptor};
