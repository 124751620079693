import {useEffect, useMemo, useState} from 'react';

import {Link} from 'react-router-dom';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {current_phase, secondToDate, convertBalance, convertBalanceNumber, secondToDateNoSec} from '@/utils/SuiObject';
import Countdown, {zeroPad} from 'react-countdown';
import {SuiObjectName} from '@/utils/constant';
import {message} from 'antd';
import StakingBg from '../assets/icon/sale-bg.png';
import balanceIcon from '../assets/icon/balance-icon.png';
import screenIcon from '../assets/icon/screen-icon.png';
import shieldIcon from '../assets/icon/shield-icon.png';
import enableSaleIcon from '../assets/icon/sale-enable-icon.svg';
import disSaleIcon from '../assets/icon/sale-disicon.svg';

import slider12 from '../assets/images/slider/slider-12.png';
import slider13 from '../assets/images/slider/slider-13.png';
import slider14 from '../assets/images/slider/slider-14.png';
import slider15 from '../assets/images/slider/slider-15.png';
import ClockIcon from '@/assets/icon/Clock.svg';
import CoinVerticalIcon from '@/assets/icon/CoinVertical.svg';
import TagIcon from '@/assets/icon/Tag.svg';
import StarIcon from '@/assets/icon/Star.svg';

// TODO: change to 1 after fix
const WL_PHASE_NUM = 1;

let regexp = /^\d+\.?\d{0,6}$/;

const {SuiToken, DRAToken} = SuiObjectName;

message.config({
  maxCount: 1,
});

function PublicSale(props) {
  const {
    saleObject,
    isWhiteList,
    systemObject,
    addressWallet,
    balanceWallet,
    requestBuyPublicSalesV1,
    requestBuyPublicOgSales,
  } = useBlockchainContext();

  //console.log('currentCRA', current_phase(phases));
  const [inputAmountPhase01, setInputAmountPhase01] = useState('');
  const [inputAmountPhase02, setInputAmountPhase02] = useState('');
  const [inputAmountPhase03, setInputAmountPhase03] = useState('');

  const phases = useMemo(() => systemObject?.fields?.parameters?.fields?.token_public?.fields?.phases, [systemObject]);

  const dataPhasePublicSale = phases?.map((phase, index) => {
    const phaseData = phase?.fields?.phase_data?.fields;

    const formatPhaseData = {
      ...phaseData,
      startTime: !Number(phaseData.start_time) ? 'N/A' : secondToDateNoSec(phaseData.start_time),
      endTime: !Number(phaseData.start_time) ? 'N/A' : secondToDateNoSec(phaseData.end_time),
      phaseName: `Phase ${phaseData?.phase_num}`,
      maxAmountForUser: phaseData?.max_amount_for_user,
      SUIPerDRA: phaseData?.sui_per_dra / 10 ** 9,
      enabled:
        phaseData?.enable &&
        Number(phaseData.start_time) * 1000 < Date.now() &&
        Number(phaseData.end_time) * 1000 > Date.now(),
      ended: Number(phaseData.end_time) * 1000 < Date.now(),
      SUIPerDRAOG: phaseData?.sui_per_dra_og / 10 ** 9,
      draBalanceAmount: convertBalance(phaseData?.dra_balance_amount),
    };
    if (index === 0) {
      formatPhaseData.inputAmount = inputAmountPhase01;
      formatPhaseData.setInputAmount = setInputAmountPhase01;
    }
    if (index === 1) {
      formatPhaseData.inputAmount = inputAmountPhase02;
      formatPhaseData.setInputAmount = setInputAmountPhase02;
    }
    if (index === 2) {
      formatPhaseData.inputAmount = inputAmountPhase03;
      formatPhaseData.setInputAmount = setInputAmountPhase03;
    }
    return formatPhaseData;
  });

  const currentPhase = current_phase(phases);
  const currPhaseCRABal = currentPhase?.dra_balance_amount || 0;

  const [isInWL, setIsInWL] = useState(false);
  const [maxMint, setMaxMint] = useState(0);

  useEffect(() => {
    const getSale = async () => {
      let isWL = await isWhiteList(addressWallet);

      const currPhaseNum = current_phase(phases)?.phase_num;

      const maxAmount = dataPhasePublicSale.find((i) => i.phase_num === currPhaseNum)?.maxAmountForUser;

      if (isWL && currPhaseNum === WL_PHASE_NUM) {
        const currOGPhase = saleObject?.fields?.og_phases[0];
        const OGUser =
          currOGPhase?.fields?.users !== ''
            ? currOGPhase?.fields?.users?.find((el) => el?.fields?.sui_address === addressWallet)
            : null;
        const amountDRAStaked = OGUser?.fields?.total_dra_amount || 0;

        setIsInWL(true);
        setMaxMint(maxAmount - amountDRAStaked);
      } else setIsInWL(false);
    };

    getSale();
  }, [systemObject, saleObject, isWhiteList, addressWallet, dataPhasePublicSale, phases]);

  //console.log('systemObject', systemObject);

  const onClickHandler = async (e, inputAmount, setInputAmount) => {
    e.preventDefault();

    try {
      if (inputAmount <= 0) {
        message.error(`Mint amount must greater than zero!`);
        return;
      }

      if (inputAmount > currPhaseCRABal / 10 ** 9) {
        message.error(`You can only mint max ${convertBalance(currPhaseCRABal)} DRA !`);
        return;
      }

      await requestBuyPublicSalesV1(Number(inputAmount), current_phase(phases)?.sui_per_dra);
      setInputAmount('');
    } catch (error) {
      console.log('error', error);
      setInputAmount('');
    }
  };

  const onClickHandlerForWL = async (e, inputAmount, setInputAmount) => {
    e.preventDefault();

    //TODO: Check balance

    try {
      if (inputAmount <= 0) {
        message.error(`Mint amount must greater than zero!`);
        return;
      }

      if (isInWL && inputAmount > maxMint / 10 ** 9) {
        message.error(`You can only mint max ${convertBalance(maxMint)} DRA !`);
        return;
      }

      await requestBuyPublicOgSales(Number(inputAmount), current_phase(phases)?.sui_per_dra_og);

      setInputAmount('');
    } catch (error) {
      console.log('error', error);
      setInputAmount('');
    }
  };

  const dataBalance = [
    {
      id: 1,
      title: 'Phase Available for Sale',
      balance: convertBalance(currPhaseCRABal) ?? 0,
      denom: 'DRA',
      icon: balanceIcon,
    },
    {
      id: 2,
      title: 'My DRA Balance',
      balance: balanceWallet[DRAToken] ? convertBalance(balanceWallet[DRAToken]) : 0,
      denom: 'DRA',
      icon: screenIcon,
    },
    {
      id: 3,
      title: 'My SUI Balance',
      balance: balanceWallet[SuiToken] ? convertBalance(balanceWallet[SuiToken]) : 0,
      denom: 'SUI',
      icon: shieldIcon,
    },
  ];

  return (
    <div className="public-sale-page">
      <div className="head-section">
        <img className="w-100 staking-bg" src={StakingBg} alt="bg" />
        <div className="col-md-12 content-block ">
          <div className="col-md-8 mx-auto tf-slider-item style-3">
            <div className="content-inner">
              <img src={slider12} alt="slider12" className="img-star star-1 ani4" />
              <img src={slider13} alt="slider13" className="img-star star-2 ani5" />
              <img src={slider14} alt="slider14" className="img-star star-3 ani4" />
              <img src={slider15} alt="slider15" className="img-star star-4 ani5" />
              <h1 className="heading ">Public Sale</h1>
              <p className="sub-heading" style={{fontSize: '16px'}}>
                DRA Tokens are available for public acquisition for limited period of time. The available DRA is issued
                on a First Come First Served basis. If you have OG Role, you will be in the whitelist and you are
                entitled to a certain amount of DRA.
              </p>
            </div>
          </div>
          <div className="container mx-auto tf-slider-item style-3">
            <div className="balance-block col-md-12">
              {dataBalance?.map((idx) => (
                <div key={idx.id} className="balance-cont">
                  <img src={idx?.icon} className="icon-balance" alt="icons" />
                  <div>
                    <div className="block-txt">{idx.title}</div>
                    <div className="block-content">
                      {idx.balance} {idx.denom}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <section className="tf-connect-wallet">
        <div className="tf-container">
          <div className="row justify-content-center">
            {dataPhasePublicSale?.map(({inputAmount, setInputAmount, ...item}) => (
              <div key={item.id} className="col-12 col-lg-6 col-xl-4">
                <div
                  className="tf-wallet countdown-text"
                  style={{
                    background: 'transparent',
                    minHeight: '200px',
                    border:
                      item.phase_num !== currentPhase?.phase_num
                        ? '1px solid var(--product-color18)'
                        : '2px solid var(--primary-color3)',
                    cursor: 'auto',
                  }}
                >
                  <div className="d-flex align-items-center title">
                    <h6 className="mr-3">{item.phaseName}</h6>
                    <img src={item.enabled ? enableSaleIcon : disSaleIcon} alt="icon" />
                  </div>

                  <p className="content mb-3">{item?.ended ? 'Ended' : item.enabled ? 'End in' : 'Start in'}</p>

                  {item.enabled ? <CustomCountdown date={item.endTime} /> : <CustomCountdown date={item.startTime} />}

                  <div className="d-flex justify-content-between my-4">
                    <div className="d-flex">
                      <img src={TagIcon} className="icon-content" alt="sui"/>
                      <p className="content mx-3">Total available: </p>
                    </div>
                    <p className="content-desc">{item.draBalanceAmount} DRA</p>
                  </div>

                  <div className="d-flex  justify-content-between  my-4">
                    <div className="d-flex">
                      <img src={ClockIcon} className="icon-content" alt="sui"/>
                      <p className="content mx-3">Start: </p>
                    </div>
                    <p className="content-desc">{item.startTime}</p>
                  </div>

                  <div className="d-flex  justify-content-between  my-4">
                    <div className="d-flex">
                      <img src={ClockIcon} className="icon-content" alt="sui"/>
                      <p className="content mx-3">End: </p>
                    </div>
                    <p className="content-desc">{item.endTime}</p>
                  </div>

                  <div className="d-flex  justify-content-between  my-4">
                    <div className="d-flex">
                      <img src={CoinVerticalIcon} className="icon-content" alt="sui"/>
                      <p className="content mx-3">Public price: </p>
                    </div>
                    <p className="content-desc">{item.SUIPerDRA} SUI/DRA</p>
                  </div>
                  {item.phase_num === WL_PHASE_NUM && (
                    <div className="d-flex  justify-content-between  my-4">
                       <div className="d-flex">
                        <img src={CoinVerticalIcon} className="icon-content" alt="sui"/>
                        <p className="content mx-3">Whitelist price: </p>
                      </div>
                      <p className="content-desc">{item?.SUIPerDRAOG} SUI/DRA</p>
                    </div>
                  )}

                  {/* <div className="d-flex my-4">
                    <img src={StarIcon} className="icon-content" alt="sui"/>
                    <p className="content mx-3">Status: </p>
                    <p className="content-desc">
                      {item.enabled ? (
                        <span >Active</span>
                      ) : (
                        <span className="text-dark">Inactive</span>
                      )}
                    </p>
                  </div> */}
                  <div style={{minHeight: '24px'}}>
                    {item.phase_num !== WL_PHASE_NUM ? (
                      <></>
                    ) : isInWL ? (
                      <div>
                        You are in whitelist, you can acquire upto
                        <span className="text-green"> {convertBalance(maxMint)} DRA</span>
                      </div>
                    ) : (
                      <div className="text-green">You are NOT in whitelist.</div>
                    )}
                  </div>
                  <div className="my-4" style={{fontSize: '14px'}}>
                    {item.enabled && (
                      <div style={{minHeight: '24px'}}>
                        <div>
                          Public buy - estimated cost :{' '}
                          <span className="text-green">{(inputAmount * item?.sui_per_dra) / 10 ** 9} SUI </span>
                          {item.phase_num === WL_PHASE_NUM && isInWL && (
                            <div className="">
                              {' '}
                              Whitelist buy - estimated cost: {(inputAmount * item?.sui_per_dra_og) / 10 ** 9} SUI{' '}
                            </div>
                          )}
                        </div>{' '}
                      </div>
                    )}{' '}
                  </div>

                  {item?.enabled && (
                    <div
                      className="row my-4 mx-auto w-100"
                      style={{
                        maxWidth: '420px',
                        minHeight: '60px',
                      }}
                    >
                      <input
                        type="number"
                        value={!item.enabled ? '' : inputAmount}
                        style={{borderRadius: '35px'}}
                        placeholder="Enter DRA amount to buy"
                        onChange={({target}) => {
                          console.log('setInputAmount', target.value);
                          if (!target.value) {
                            item.enabled && setInputAmount('');
                            return;
                          }
                          if (target.value < 0) {
                            setInputAmount(0);
                            return;
                          }
                          if (!regexp.test(target.value)) return;
                          console.log(Number(target.value));
                          item.enabled && setInputAmount(Number(target.value));
                        }}
                      />
                    </div>
                  )}

                  {item?.enabled && (
                    <div
                      className="d-flex justify-content-center mx-auto w-100"
                      style={{
                        maxWidth: '420px',
                      }}
                    >
                      {item.phase_num === WL_PHASE_NUM ? (
                        <>
                          <button
                            style={{background: !isInWL ? 'grey' : '#3749E9', color: !isInWL ? 'darkgrey' : '#fff'}}
                            className="submit w-100 px-2 mx-1"
                            type="submit"
                            onClick={
                              !item.enabled
                                ? () => {
                                    console.log('jjj');
                                  }
                                : (e) => onClickHandlerForWL(e, inputAmount, setInputAmount)
                            }
                            disabled={!isInWL}
                          >
                            WHITELIST BUY
                          </button>
                          <button
                            className="submit w-100 px-4 mx-1"
                            type="submit"
                            onClick={!item.enabled ? () => {} : (e) => onClickHandler(e, inputAmount, setInputAmount)}
                          >
                            PUBLIC BUY
                          </button>
                        </>
                      ) : (
                        <button
                          className="submit w-100 px-4"
                          type="submit"
                          onClick={!item.enabled ? () => {} : (e) => onClickHandler(e, inputAmount, setInputAmount)}
                        >
                          BUY NOW
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default PublicSale;

export const CustomCountdown = ({date}) => {
  return (
    <Countdown
      date={date}
      daysInHours={false}
      renderer={({days, hours, minutes, seconds, completed}) => {
        if (completed || date === 'N/A') {
          return (
            <div className="sc-product d-flex justify-content-center mx-2 px-3">
              <div
                className=""
                style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '24px', }}
              >
                Phase not available!
              </div>
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-between ">
              <div
                style={{
                  borderRadius: '15px',
                  marginBottom: '30px',
                }}
                className=" mx-1 md:mx-2 px-2 block-time"
              >
                <div
                  style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '30px', }}
                  className="time-text"
                >
                  {zeroPad(days)}
                </div>
                <div className="desc-txt">Days</div>
              </div>

              <div
                style={{
                  borderRadius: '15px',
                  marginBottom: '34px',
                }}
                className="mx-0 px-0 block-time"
              >
                <div
                  style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '32px', }}
                  className="time-text"
                >
                  :
                </div>
              </div>

              <div
                style={{
                  borderRadius: '15px',
                  marginBottom: '30px',
                }}
                className=" mx-1 md:mx-2 px-2 block-time"
              >
                <div
                  style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '32px', }}
                  className="time-text"
                >
                  {zeroPad(hours)}
                </div>
                <div className="desc-txt">Hours</div>
              </div>

              <div
                style={{
                  borderRadius: '15px',
                  marginBottom: '34px',
                }}
                className="mx-0 px-0 block-time"
              >
                <div
                  style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '32px', }}
                  className="time-text"
                >
                  :
                </div>
              </div>

              <div
                style={{
                  borderRadius: '15px',
                  marginBottom: '30px',
                }}
                className="mx-1 md:mx-2 px-2 block-time"
              >
                <div
                  style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '32px', }}
                  className="time-text"
                >
                  {zeroPad(minutes)}
                </div>
                <div className="desc-txt">Mins</div>
              </div>

              <div
                style={{
                  borderRadius: '15px',
                  marginBottom: '34px',
                }}
                className="mx-0 px-0 block-time"
              >
                <div
                  style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '32px', }}
                  className="time-text"
                >
                  :
                </div>
              </div>

              <div
                style={{
                  borderRadius: '15px',
                  marginBottom: '30px',
                }}
                className=" mx-1 md:mx-2 px-2 block-time"
              >
                <div
                  style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '32px', }}
                  className="time-text"
                >
                  {zeroPad(seconds)}
                </div>
                <div className="desc-txt">Secs</div>
              </div>
            </div>
          );
        }
      }}
    />
  );
};
