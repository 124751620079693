import {Button, Modal, Input, Form, Switch, Select, Checkbox, message, Upload, Spin} from 'antd';
import {RcFile, UploadFile, UploadProps} from 'antd/lib/upload/interface';
import {useState} from 'react';
import {uploadFile} from 'react-s3';
import icon5 from '../../assets/images/svg/drap-upload.svg';
import ImageUpload from '@/assets/images/upload-image.png';
import './style.scss';

import {Buffer} from 'buffer';
window.Buffer = window.Buffer || Buffer;


const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

export const UploadImage = ({setFileUpload, uploadEle, title, descSize, fileListProp, className}) => {
  const [fileList, setFileList] = useState(fileListProp || []);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return false;
  };

  const [loading, setLoading] = useState(false);

  const handleUpload = async (file) => {
    setLoading(true);
    console.log({err: file});

    uploadFile(file.originFileObj, config)
      .then((data) => {
        setFileList([...fileList, file]);
        setFileUpload(data);
        setLoading(false);
        message.success('Upload image successfully');
      })
      .catch((err) => {
        console.log({err: err});
        setLoading(false);
        message.error('Upload image failed');
      });
  };

  const handleChange = ({fileList: newFileList}) => {
    if (newFileList.length > 0) {
      handleUpload(newFileList[0]);
    } else {
      setFileUpload({});
      setFileList(newFileList);
    }
  };

  const uploadButton = (
    <Spin spinning={loading}>
      <div className="text-center">
        <div className="text-pri mb-3">{title}</div>
        <div>
        <img src={icon5} alt="Binasea" />
        </div>

        <div className="text-sec">JPG, PNG, GIF, WEBP</div>
        <div className="text-sec"> {descSize}</div>
      </div>
    </Spin>
  );

  return (
    <div className={className}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        action=""
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={beforeUpload}
      >
        {fileList.length > 0 ? null : uploadEle ? uploadEle : uploadButton}
      </Upload>
    </div>
  );
};
