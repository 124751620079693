import React, {createContext, useContext, useState, useMemo, ReactNode, useEffect, FC} from 'react';
import {JsonRpcProvider} from '@mysten/sui.js';
import {useWallet} from '@suiet/wallet-kit';

interface AppUIContext {}
const UIContext = createContext<any>({});

export function useUIContext() {
  return useContext(UIContext);
}

export const UIConsumer = UIContext.Consumer;

export function AppUIProvider({children}: any) {
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false);
  useEffect(() => {}, []);
  const {connected} = useWallet();

  const checkAuth = () => {
    console.log('connected', connected);

    if (!connected) {
      setIsModalConnectOpen(true);
    }
    return connected;
  };

  const value = {
    isModalConnectOpen,
    checkAuth,
    setIsModalConnectOpen,
  };

  return (
    <UIContext.Provider value={value}>
      <div className="app-element">{children}</div>
    </UIContext.Provider>
  );
}

export const withContext = (Component: FC) => {
  return (props: any) => {
    return (
      <UIContext.Consumer>
        {(globalState) => {
          return <Component {...globalState} {...props} />;
        }}
      </UIContext.Consumer>
    );
  };
};
