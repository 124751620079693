import { useCallback, useEffect, useMemo, useState } from 'react';

const localStorageListeners: any = {};

export function useLocalStorageStringState(
  key: string,
  defaultState: string | null = null,
): [string | null, (newState: string | null) => void] {
  const state = localStorage.getItem(key) || defaultState;

  const [, notify] = useState(key + '\n' + state);

  useEffect(() => {
    if (!localStorageListeners[key]) {
      localStorageListeners[key] = [];
    }
    localStorageListeners[key].push(notify);
    return () => {
      localStorageListeners[key] = localStorageListeners[key].filter(
        (listener: any) => listener !== notify,
      );
      if (localStorageListeners[key].length === 0) {
        delete localStorageListeners[key];
      }
    };
  }, [key]);

  const setState = useCallback<(newState: string | null) => void>(
    (newState) => {
      const changed = state !== newState;
      if (!changed) {
        return;
      }

      if (newState === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, newState);
      }
      localStorageListeners[key]?.forEach((listener: any) =>
        listener(key + '\n' + newState),
      );
    },
    [state, key],
  );

  return [state, setState];
}

export function useLocalStorageState<T = any>(
  key: string,
  defaultState: T | null = null,
): [T, (newState: T) => void] {
  let [stringState, setStringState] = useLocalStorageStringState(
    key,
    JSON.stringify(defaultState),
  );
  return [
    useMemo(() => stringState && JSON.parse(stringState), [stringState]),
    (newState) => setStringState(JSON.stringify(newState)),
  ];
}

export const shortAddress = (address: string | undefined) => {
  if (!address) return "";
  return (
    address.substring(0, 4) + "..." + address.substring(address.length - 4)
  );
};

export const strToByteArray = (str: string): number[] => {
  const utf8Encode = new TextEncoder();
  return Array.from(utf8Encode.encode(str).values());
};

export const priceToNumber = (number: number) => {
  if(!number) return 0
  return number * Math.pow(10,9)
}

export const convertu64  = (number) => {
  return `${number}`
}

export const getTimeSecond = () => {
  return `${Math.floor(Date.now() / 1000)}`;
};

export const convertAddress  = (string) => {
  return `@${string}`
}
