import React from 'react';
import dataBanner from '../assets/fake-data/data-banner';
// import dataCategory from '../assets/fake-data/data-category';
// import dataCollection from '../assets/fake-data/data-collection';
import dataCreate from '../assets/fake-data/data-create';
import dataExplore from '../assets/fake-data/data-explore';
import dataHotpick from '../assets/fake-data/data-hotpick';
import dataLiveaution from '../assets/fake-data/data-liveaution';
// import dataSeller from '../assets/fake-data/data-topseller';
import Banner from '../components/banner/Banner';
// import Category from '../components/category/Category';
// import Collection from '../components/collection/Collection';
import Create from '../components/create/Create';
import Explore from '../components/explore/Explore';
// import HotPick from '../components/hotpick/HotPick';
import LiveAutions from '../components/liveautions/FeaturedCollection';
// import TopSeller from '../components/topseller/TopSeller';

function Home(props) {
  return (
    <div className="home-1">
      <div id="page">
        <Banner data={dataBanner} />
        <LiveAutions data={dataLiveaution} />
        <Explore data={dataExplore} />
        <Create data={dataCreate} />

        {/* <HotPick data={dataHotpick} /> */}
        {/* <Category data={dataCategory} /> */}
        {/* <Collection data={dataCollection} /> */}
        {/* <TopSeller data={dataSeller} /> */}
      </div>
    </div>
  );
}

export default Home;
