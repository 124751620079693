import {useQuery} from '@tanstack/react-query';
import {isEmpty} from 'lodash';
import API from '@/hooks/api/useCallAPI';

const fetcher = async (params) => {
  const rs = await API.get(`/nfts/check/${params?.nftId}`);
  return rs.data;
};

const useNFTQuery = (params) => {
  const fn = useQuery(
    ['NFTs', params],
    () => {
      return fetcher(params);
    },
    {
      refetchOnWindowFocus: true,
      retry: false,
    }
  );
  return {
    ...fn,
  };
};

export {useNFTQuery};
