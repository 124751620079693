import { SuiMoveObject } from '@mysten/sui.js';
import {
    Nft,
    NftRpcResponse,  
    NftCollection,
    NftCollectionRpcResponse,
    CollectionManager,
    CollectionManagerRpcResponse,
    Marketplace,
    MarketplaceRpcResponse,
    SuiObjectParser
} from './types';

const CollectionManagerRegex = /(0x[a-f0-9]{1,})::collection::CollectionManager/;

export const CollectionManagerParser: SuiObjectParser<CollectionManagerRpcResponse, CollectionManager> = {
  parser: (data, suiData, _) => {
    const matches = (suiData.data as SuiMoveObject).type.match(CollectionManagerRegex);
    
    if (!matches) {
      return undefined;
    }
    
    const packageObjectId = matches[1];
    //const packageModule = matches[2];
    //const packageModuleClassName = matches[3];

    return {
      id: suiData.reference.objectId,
      collectionIds: data.collection_ids,
      itemByCollection: data.item_by_collection.fields.contents.length > 0 ? data.item_by_collection.fields.contents.reduce((acc, key) => {
        acc[key.fields.key] = key.fields.value;
        return acc;
      }, {} as { [c: string]: string[] }) : {} as { [c: string]: string[] },
      packageObjectId
      //packageModule,
      //packageModuleClassName
    };
  },

  regex: CollectionManagerRegex
};

const CollectionRegex = /(0x[a-f0-9]{1,})::collection::Collection/;

export const CollectionParser: SuiObjectParser<NftCollectionRpcResponse, NftCollection> = {
  parser: (data, suiData, _) => {
    const matches = (suiData.data as SuiMoveObject).type.match(CollectionRegex);
    if (!matches) {
      return undefined;
    }

    const packageObjectId = matches[1];
    //const packageModule = matches[2];
    //const packageModuleClassName = matches[3];

    return {
      id: suiData.reference.objectId,
      name: data.name,
      description: data.description,
      symbol: data.symbol,
      tags: data.tags,
      isMutable: data.is_mutable,
      royaltyFee: data.royalty_fee,
      creator: data.creator,   
      packageObjectId
      //packageModule,
      //packageModuleClassName
    };
  },

  regex: CollectionRegex
};

const NftRegex = /(0x[a-f0-9]{1,})::nft::NFT/;

export const NftParser: SuiObjectParser<NftRpcResponse, Nft> = {
  parser: (data, suiData, _) => {
    const matches = (suiData.data as SuiMoveObject).type.match(NftRegex);
    if (!matches) {
      return undefined;
    }

    const packageObjectId = matches[1];
    //const packageModule = matches[2];
    //const packageModuleClassName = matches[3];

    return {
      id: suiData.reference.objectId,
      name: data.name,
      description: data.description,
      url: data.url,
      collectionId: data.collection_id,
      attributeKeys: data.attribute_keys,
      attributeValues: data.attribute_values,
      isMutable: data.is_mutable,
      packageObjectId
      //packageModule,
      //packageModuleClassName
    };
  },

  regex: NftRegex
};


const MarketplaceRegex = /(0x[a-f0-9]{1,})::marketplace::Marketplace/;

export const MarketplaceParser: SuiObjectParser<MarketplaceRpcResponse, Marketplace> = {
  parser: (data, suiData, _) => {
    const matches = (suiData.data as SuiMoveObject).type.match(MarketplaceRegex);
    if (!matches) {
      return undefined;
    }

    const packageObjectId = matches[1];
    //const packageModule = matches[2];
    //const packageModuleClassName = matches[3];

    return {
      id: suiData.reference.objectId,
      listedItemIds: data.listed_item_ids,
      listingByCollection: data.listing_by_collection.fields.contents.length > 0 ? data.listing_by_collection.fields.contents.reduce((acc, key) => {
        acc[key.fields.key] = key.fields.value;
        return acc;
      }, {} as { [c: string]: string[] }) : {} as { [c: string]: string[] },
      packageObjectId
      //packageModule,
      //packageModuleClassName
    };
  },

  regex: MarketplaceRegex
};