import img1 from '../images/slider/slider-1.jpg';
import img2 from '../images/slider/slider-5.png';
import avt1 from '../images/slider/slider-4.png';

const dataBanner = [
  {
    id: 1,
    heading: 'Explore, collect, and sell NFTs',
    desc: 'Buy or sell NFTs and save 100% in fees. The only zero-fee marketplace on Solana.',
    img: 'https://dragonsui.pages.dev/assets/image-1.043957b4.png',
    avt: 'https://dragonsui.pages.dev/assets/image-1.043957b4.png',
    price: '1.56 SUI',
    name: '“The Monkey sad ”',
    tag: '@SolvadorDali',
  },
];

export default dataBanner;
