import {message} from 'antd';
import {useRecoilState} from 'recoil';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import API from '@/hooks/api/useCallAPI';

type FetcherParamsT = {};

const fetcher = async (params: FetcherParamsT) => {
  const rs = await API.post('/nft-farmings', params);
  return rs.data;
};

const useCreateFarmMutation = (
  onSuccess?: ((arg0: any, arg1?: any) => void) | undefined,
  onError?: ((arg0: any, arg1?: any) => void) | undefined
) => {
  const queryClient = useQueryClient();

  const fn = useMutation(['copllection', 'create'], {
    mutationFn: (variables: FetcherParamsT) => {
      return fetcher({
        ...variables,
      });
    },
    onSuccess: (rs, vars) => {
      onSuccess && onSuccess(rs, vars);
      message.success("Create Pool success")
    },
    onError: (err: any) => {
      message.error(err);
    },
    retry: false,
  });
  return {
    ...fn,
  };
};

export {useCreateFarmMutation};
