import React, {createContext, useContext, useState, useMemo, ReactNode, useEffect, FC, useCallback} from 'react';
import {
  JsonRpcProvider,
  SplitCoinTransaction,
  RpcTxnDataSerializer,
  SignerWithProvider,
  Coin,
  MoveCallTransaction,
} from '@mysten/sui.js';
import {toast} from 'react-toastify';
import {
  accountAggregateBalancesSelector,
  accountCoinsSelector,
  convertBalance,
  fetchAllOwnedAndRequiredObjects,
  getID,
  getObjectById,
  ownedObjects,
  selectCoinSetWithCombinedBalanceGreaterThanOrEqual,
  selectCoinWithBalanceEqual,
  selectCoinWithBalanceGreaterThanOrEqual,
} from '@/utils/SuiObject';
import {convertu64, useLocalStorageState} from '@/utils/utils';
import {useWallet} from '@suiet/wallet-kit';

import {
  DRA_FOR_SALE,
  DRA_STAKING_POOL_ADDRESS,
  PACKAGE_STAKING_ID,
  DRA_SYSTEM_STATE,
  DRA_TOKEN,
  SuiObjectName,
  GAS_BUDGET_MAX,
  GAS_BUDGET_MIN,
  PACKAGE_NFT_PROTOCOL_ID,
  ORIGIN_NFT_PACKAGE_ID,
} from '@/utils/constant';
import {message} from 'antd';

import {NftClient} from '../utils';
import {
  client,
  //keypair,
  //signer,
  //signer2,
  COLLECTION_MANAGER_ID,
  COLLECTION_ID,
  COLLECTION_ID2,
  PACKAGE_OBJECT_ID,
  MARKETPLACE_ID,
  PAID_COIN_ID,
  PAID_COIN_ID2,
} from '../utils/constant';
import {strToByteArray} from '../utils/utils';
import {useBlockchainContext} from './BlockchainContext';

interface AppNFTProtocolContext {}
const NFTProtocolContext = createContext<any>({});

export function useNFTProtocolContext() {
  return useContext(NFTProtocolContext);
}

export const UIConsumer = NFTProtocolContext.Consumer;

export function NFTProtocolProvider({children}: any) {
  const {addressWallet} = useBlockchainContext();
  const endpoint = process.env.REACT_APP_SUI_ENDPOINT;
  const serializer = new RpcTxnDataSerializer(endpoint);

  const {connected, getAccounts, signAndExecuteTransaction, wallet} = useWallet();

  const getAllObjectOfAddress = async (address: string) => {
    const allObjects = await fetchAllOwnedAndRequiredObjects(address);
    const allSuiObjects = ownedObjects(allObjects, address);
    return allSuiObjects;
  };

  useEffect(() => {}, []);

  const getAllCoinsObjects = async (address) => {
    const allSuiObjects = await getAllObjectOfAddress(address);
    const coins = accountCoinsSelector(allSuiObjects);

    return coins;
  };

  const getAllStakeObjects = async (address) => {
    const allSuiObjects = await getAllObjectOfAddress(address);
    const coins = accountCoinsSelector(allSuiObjects);
    console.log(allSuiObjects);
    return coins;
  };

  const getCoinsObjectsByType = async (address) => {
    const coins = await getAllCoinsObjects(address);
    return coins.filter((el) => Coin.getCoinTypeArg(el) === SuiObjectName.DRAToken);
  };

  const getSuiObjectsByType = async (address) => {
    const coins = await getAllCoinsObjects(address);
    return coins.filter((el) => Coin.getCoinTypeArg(el) === SuiObjectName.SuiToken);
  };

  const executeMoveCallNFTProtocolModule = async (
    moduleName,
    typeArgs: Array<string>,
    methodName: string,
    arg?: any
  ) => {
    try {
      const transaction: MoveCallTransaction = {
        packageObjectId: PACKAGE_NFT_PROTOCOL_ID,
        module: moduleName,
        function: methodName,
        typeArguments: typeArgs,
        arguments: arg,
        gasBudget: 10000,
      };

      const result = await signAndExecuteTransaction({
        transaction: {
          kind: 'moveCall',
          data: transaction,
        },
      });
      return result;
    } catch (err) {
      console.log(err);
      message.error('Transaction failed!');
      throw err;
    }
  };

  const executeMoveCallOriginNFTProtocolModule = async (
    moduleName,
    typeArgs: Array<string>,
    methodName: string,
    arg?: any
  ) => {
    try {
      const transaction: MoveCallTransaction = {
        packageObjectId: ORIGIN_NFT_PACKAGE_ID,
        module: moduleName,
        function: methodName,
        typeArguments: typeArgs,
        arguments: arg,
        gasBudget: 10000,
      };

      const result = await signAndExecuteTransaction({
        transaction: {
          kind: 'moveCall',
          data: transaction,
        },
      });
      return result;
    } catch (err) {
      console.log(err);
      message.error('Transaction failed!');
      throw err;
    }
  };

  const saleOnNft = async (argument) => {
    const res = await executeMoveCallOriginNFTProtocolModule('listing', [], 'sale_on', argument);

    message.success('List NFT successfully');
  };

  const createMarketWithInventory = async (argument) => {
    const res = await executeMoveCallOriginNFTProtocolModule(
      'fixed_price',
      [SuiObjectName.SuiToken],
      'init_market_with_inventory',
      argument
    );

    message.success('List NFT successfully');
  };

  const saleOffNft = async (argument) => {
    const res = await executeMoveCallOriginNFTProtocolModule('listing', [], 'sale_off', argument);

    message.success('Unlist NFT successfully');
  };

  const initInventory = async () => {
    const res = await executeMoveCallOriginNFTProtocolModule('inventory', [], 'init_inventory', []);

    message.success('Init inventory successfully');
    return res;
  };

  const addNft = async () => {
    const res = await executeMoveCallOriginNFTProtocolModule('inventory', [], 'init_inventory', []);

    message.success('Init inventory successfully');
    return res;
  };

  const initInventoryAndListing = async (witness) => {
    const res = await executeMoveCallNFTModule(witness, 'init_inventory_and_listing', []);

    message.success('Create market successfully');
    return res;
  };

  const createMarketsOnInventory = async (witness, args) => {
    const res = await executeMoveCallNFTModule(witness, 'create_markets_on_inventory', args, ['sui::sui::SUI']);

    message.success('Create market successfully');
    console.log(res, 'resresres');

    return res;
  };

  const listingOnInventory = async (witness, args) => {
    const res = await executeMoveCallNFTModule(witness, 'listing_on_inventory', args, []);

    message.success('Listing on inventory successfully');
    return res;
  };

  const saleOnMarket = async (witness, args) => {
    const res = await executeMoveCallNFTModule(witness, 'sale_on_market', args, []);

    message.success('List NFT successfully');
    return res;
  };

  const saleOffMarket = async (witness, args) => {
    const res = await executeMoveCallNFTModule(witness, 'sale_off_market', args, []);

    message.success('Delisting NFT successfully');
    return res;
  };

  const buyNftCert = async (
    collection: string,
    listingId,
    inventoryId: string,
    marketId: string,
    price: number,
  ) => {
    let coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
    console.log({coinsSuiObjectId: coinsSuiObjectId}, price, 'price');
    await splitSuiGas();

    const coinObject = selectCoinWithBalanceGreaterThanOrEqual(coinsSuiObjectId, BigInt(price), []);

    // Check if sui is enough:
    let totalSuiBalance = await accountAggregateBalancesSelector(coinsSuiObjectId);
    if (totalSuiBalance[SuiObjectName.SuiToken] >= price) {
      const res = await executeMoveCallNFTModule(
        collection,
        'buy_nft',
        [listingId, inventoryId, marketId, getID(coinObject)],
        [collection, 'sui::sui::SUI']
      );

      if (res?.effects?.status?.status == 'success') {
        message.success('Buy NFT successfully');
        return res;
      } else {
        message.error(`Buy NFT failure. Transactions: ${res?.effects?.transactionDigest}`);
      }
    } else {
      message.error('SUI is not enough!');
    }
  };

  const claimNftCert = async (fixedMarket: string, nftId: string, nftCert: string, witnessTypeArg: string) => {
    const res = await executeMoveCallNFTProtocolModule(
      'slingshot',
      [witnessTypeArg, SuiObjectName.DRAPrice, SuiObjectName.DRAUniqueNft],
      'claim_nft_embedded',
      [fixedMarket, nftId, nftCert, addressWallet]
    );

    message.success('Claim NFT successfully');
    return res;
  };

  const splitModule = (module: string) => {
    return module.split('::').map((el) => el.trim());
  };

  const executeMoveCallNFTModule = async (moduleName, methodName: string, arg: any, typeArg: any = []) => {
    const [objectId, module] = splitModule(moduleName);
    try {
      const transaction: MoveCallTransaction = {
        packageObjectId: objectId,
        module: module,
        function: methodName,
        typeArguments: typeArg,
        arguments: arg,
        gasBudget: 10000,
      };

      const result = await signAndExecuteTransaction({
        transaction: {
          kind: 'moveCall',
          data: transaction,
        },
      });

      return result;
    } catch (err) {
      console.log(err);
      message.error('Transaction failed!');
      throw err;
    }
  };

  const splitSuiGas = async () => {
    let coinsSuiObjectId = await getSuiObjectsByType(addressWallet);
    console.log({coinsSuiObjectId: coinsSuiObjectId});

    let gasObject = selectCoinSetWithCombinedBalanceGreaterThanOrEqual(coinsSuiObjectId, GAS_BUDGET_MAX * 2, []);

    if (gasObject.length < 2) {
      const transaction1: SplitCoinTransaction = {
        coinObjectId: getID(gasObject[0]),
        splitAmounts: [GAS_BUDGET_MAX],
        gasBudget: GAS_BUDGET_MIN,
      };
      const res1 = await signAndExecuteTransaction({
        transaction: {
          kind: 'splitCoin',
          data: transaction1,
        },
      });
      return true;
    }
  };

  const value = {
    saleOnNft,
    saleOffNft,
    buyNftCert,
    claimNftCert,
    initInventory,
    initInventoryAndListing,
    createMarketWithInventory,
    createMarketsOnInventory,
    listingOnInventory,
    saleOnMarket,
    saleOffMarket,
  };

  return (
    <NFTProtocolContext.Provider value={value}>
      <div className="app-element">{children}</div>
    </NFTProtocolContext.Provider>
  );
}

export const withNFTContext = (Component: FC) => {
  return (props: any) => {
    return (
      <NFTProtocolContext.Consumer>
        {(globalState) => {
          console.log(globalState, props, 'props');

          return <Component {...globalState} {...props} />;
        }}
      </NFTProtocolContext.Consumer>
    );
  };
};
