import {useQuery} from '@tanstack/react-query';
import {isEmpty} from 'lodash';
import API from '@/hooks/api/useCallAPI';

type FetcherParamsT = {
  collectionId: string;
};

const fetcher = async (params: FetcherParamsT) => {
  const rs = await API.get(`/nft-collections/collectionId/${params?.collectionId}`);
  
  return rs?.data;
};

const useCollectionQuery = (params: FetcherParamsT) => {
  const fn = useQuery(
    ['collection', params],
    () => {
      return fetcher({
        ...params,
      });
    },
    {
      enabled: !!params?.collectionId,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  return {
    ...fn,
  };
};

export {useCollectionQuery};
