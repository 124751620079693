import { JsonRpcProvider } from '@mysten/sui.js';
import { 
    SuiObjectParser, 
    GetCollectionsParams,
    GetNftsParams
} from './types';
import {
    CollectionManagerParser,
    CollectionParser,
    NftParser,
    MarketplaceParser,
} from './parsers';
import { isObjectExists } from './utils';
import { 
    buildMintToSenderTx, 
    buildCreateCollectionTx,
    buildListTx,
    buildDelistAndTakeTx,
    buildPurchaseAndTakeMutTx,
    buildMintToSenderWalletTx, 
    buildCreateCollectionWalletTx,
    buildListWalletTx,
    buildDelistAndTakeWalletTx,
    buildPurchaseAndTakeMutWalletTx,
} from './txBuilders';

const TESTNET_URL = 'https://fullnode.devnet.sui.io';

export class NftClient {
    private provider: JsonRpcProvider;
  
    constructor(_provider = new JsonRpcProvider(TESTNET_URL)) {
        this.provider = _provider;
    }

    private fetchObjectIdsForAddress =
        async <RpcResponse, DataModel>(address: string, parser: SuiObjectParser<RpcResponse, DataModel>): Promise<string[]> => {
        const objectsForWallet = await this.provider.getObjectsOwnedByAddress(address);
        return objectsForWallet
            .filter((_) => _.type.match(parser.regex))
            .map((_) => _.objectId);
    }

    fetchAndParseObjectsById = async <RpcResponse, DataModel>(
        ids: string[], parser: SuiObjectParser<RpcResponse, DataModel>): Promise<DataModel[]> => {
        const objects = await this.provider.getObjectBatch(ids);

        const parsedObjects = objects
        .filter(isObjectExists)
        .map((_) => {
            if (typeof _.details === 'object' && 'data' in _.details && 'fields' in _.details.data && _.details.data.type.match(parser.regex)) {
                return parser.parser(_.details.data.fields as RpcResponse, _.details, _);
            }
            return undefined;
        })
        .filter((_): _ is DataModel => !!_);

        return parsedObjects;
    }

    getCollectionManager  = async (collection_manager_id: string) => {
        // Read collection manager
        const objects = await this.fetchAndParseObjectsById([collection_manager_id], CollectionManagerParser);

        if (objects.length == 1) {         
            return objects[0];
        } else 
            return null;
    }

    getCollectionsById = async (params: GetCollectionsParams) => {
        const collections = await this.fetchAndParseObjectsById(params.objectIds, CollectionParser);
        return collections;
    }

    getCollectionList  = async (collection_manager_id: string) => {
        // Read collection manager
        let collectionManager = await this.getCollectionManager(collection_manager_id);
        
        if (collectionManager && collectionManager.collectionIds.length > 0) {         
            // Get collectionIds and get collections
            const collections = await this.getCollectionsById({ objectIds: collectionManager.collectionIds });
            return collections;
        }
        
        return [];
    }
    
    /*  
    getCollectionsForAddress = async (address: string) => {
        const collectionIds = await this.fetchObjectIdsForAddress(address, CollectionParser);
        const collections = await this.getCollectionsById({ objectIds: collectionIds });
        return collections;
    }
    */

    getNftsById = async (params: GetNftsParams) => {
        const nfts = await this.fetchAndParseObjectsById(params.objectIds, NftParser);
        return nfts;
      }

    getNftsForAddress = async (address: string) => {
        const nftIds = await this.fetchObjectIdsForAddress(address, NftParser);
        const nfts = await this.getNftsById({ objectIds: nftIds });
        return nfts;
    }

    getMarketplaceById  = async (market_id: string) => {
        // Read collection manager
        const objects = await this.fetchAndParseObjectsById([market_id], MarketplaceParser);

        if (objects.length == 1) {         
            return objects[0];
        } else 
            return null;
    }

    //static buildMintToSenderTx = buildMintToSenderTx;
    //static buildCreateCollectionTx = buildCreateCollectionTx;
    //static buildListTx = buildListTx;
    //static buildDelistAndTakeTx = buildDelistAndTakeTx;
    //static buildPurchaseAndTakeMutTx = buildPurchaseAndTakeMutTx;

    static buildMintToSenderWalletTx = buildMintToSenderWalletTx;
    static buildCreateCollectionWalletTx = buildCreateCollectionWalletTx;
    static buildListWalletTx = buildListWalletTx;
    static buildDelistAndTakeWalletTx = buildDelistAndTakeWalletTx;
    static buildPurchaseAndTakeMutWalletTx = buildPurchaseAndTakeMutWalletTx;
}  