import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PageTitle from '@/components/pagetitle/PageTitle';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import img from '@/assets/images/background/thumb-pagetitle.jpg';
import avt from '@/assets/images/author/author-db.jpg';

import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {useCollectionsQuery, useNFTsQuery} from '@/hooks';
import {shortAddress} from '@/utils/utils';
import './style.scss';
import {Form, message} from 'antd';
import {UploadImage} from '@/components/UploadImage';
import {useCreateUserMutation} from '@/hooks/user/useCreateUserMutation';
import {useUserQuery} from '@/hooks/user/useUserQuery';
import {convertBalanceNumber} from '@/utils/SuiObject';

ProfilePage.propTypes = {};

function ProfilePage(props) {
  const navigate = useNavigate();
  const {addressWallet} = useBlockchainContext();
  const {data: dataCollections} = useCollectionsQuery({});
  const [searchParams] = useSearchParams();
  const [fileUpload, setFileUpload] = useState({});
  const {mutateAsync: updateProfile} = useCreateUserMutation();
  const {data: dataUser, refetch} = useUserQuery({where: {address: addressWallet}});

  const {data: nfts} = useNFTsQuery();
  const [form] = Form.useForm();

  const collections = useMemo(
    () =>
      dataCollections
        ?.filter((ele) => !!ele.dataObject?.ret?.collection?.witnessTypeArg && ele?.data.includes('avatarImg'))
        .map((el) => ({...el, ...JSON.parse(el.data)})),
    [dataCollections]
  );
  useEffect(() => {
    if (dataUser?.length) {
      const userInfo = dataUser[0];
      form.setFieldsValue({
        ...userInfo,
        description: userInfo?.dataObject?.description,
        discordUrl: userInfo?.dataObject?.discordUrl,
      });
    }
  }, [dataUser]);

  const address = searchParams.get('address');

  //   const getCollectionById = useCallback(
  //     (collectionId) => {
  //       return collections?.find((el) => el.collectionId === collectionId);
  //     },
  //     [collections]
  //   );

  //   const nftByCollection = useMemo(() => {
  //     const nftByCol = {};
  //     nfts?.map((el) => (nftByCol[el.collectionId] = [...(nftByCol?.[el.collectionId] || []), el]));
  //     console.log('nftByCol', nftByCol);
  //     return nftByCol;
  //   }, [addressWallet, nfts]);

  const myCollections = useMemo(
    () => collections?.filter((ele) => ele?.authority === (address || addressWallet)),
    [collections, addressWallet]
  );

  const myNFTs = useMemo(
    () => nfts?.filter((ele) => ele?.nftOwner === (address || addressWallet)),
    [nfts, addressWallet]
  );
  const userInfo = dataUser?.[0];

  const onUpdateProfile = async (profile) => {
    console.log(profile, fileUpload, 'fileUpload');
    await updateProfile({
      address: addressWallet,
      name: profile.name,
      twitterUrl: profile.twitterUrl,
      instagramUrl: profile.instagramUrl,
      telegramUrl: profile.telegramUrl,
      dataObject: {
        description: profile.description,
        discordUrl: profile.discordUrl,
        avatarImg: fileUpload?.isEdit ? fileUpload?.location : fileUpload?.location || userInfo?.dataObject?.avatarImg,
      },
    });
    refetch();
  };

  const isValidWebsite = (url) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
  };

  return (
    <div className="profile-page">
      <section class="tf-page-title ">
        <div class="tf-container">
          <div class="row">
            <div class="col-md-12">
              <ul class="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Profile</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="thumb-pagetitle">
              <img src={img} alt="images" />
            </div>
          </div>
        </div>
      </section>

      <section className="tf-dashboard tf-tab">
        <div className="tf-container">
          <Tabs className="dashboard-filter">
            <div className="row ">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <div className="dashboard-user">
                  <div className="dashboard-infor">
                    <div className="avatar">
                      <img src={userInfo?.dataObject?.avatarImg || avt} alt="images" />
                    </div>
                    <div className="name">{userInfo?.name || 'N/A'}</div>
                    <div className="pax mb-2">{shortAddress(address || addressWallet)}</div>
                    <div className="description">{userInfo?.dataObject?.description}</div>
                    <ul className="social-item">
                      {!!userInfo?.telegramUrl && (
                        <li>
                          <a href={userInfo?.telegramUrl} target="_blank" rel="noreferrer">
                            <i class="fa-brands fa-telegram"></i>
                          </a>
                        </li>
                      )}
                      {!!userInfo?.twitterUrl && (
                        <li>
                          <a href={userInfo?.twitterUrl} target="_blank" rel="noreferrer">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                      )}
                      {!!userInfo?.dataObject?.discordUrl && (
                        <li>
                          <a href={userInfo?.dataObject?.discordUrl} target="_blank" rel="noreferrer">
                            <i class="fa-brands fa-discord"></i>
                          </a>
                        </li>
                      )}
                      {!!userInfo?.instagramUrl && (
                        <li>
                          <a href={userInfo?.instagramUrl} target="_blank" rel="noreferrer">
                            <i class="fa-brands fa-instagram"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <TabList className="filter-menuu menu-tab">
                    <Tab>
                      <a>
                        {' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{' '}
                        My Collections
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            className="svg-fill"
                            d="M18.3329 9.14297V10.8596C18.3329 11.318 17.9662 11.693 17.4995 11.7096H15.8662C14.9662 11.7096 14.1412 11.0513 14.0662 10.1513C14.0162 9.6263 14.2162 9.13464 14.5662 8.79297C14.8745 8.4763 15.2995 8.29297 15.7662 8.29297H17.4995C17.9662 8.30964 18.3329 8.68464 18.3329 9.14297Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.0587 12.9596H15.867C14.2837 12.9596 12.9503 11.768 12.817 10.2513C12.742 9.38464 13.0587 8.51797 13.692 7.9013C14.2253 7.3513 14.967 7.04297 15.767 7.04297H17.0587C17.3003 7.04297 17.5003 6.84297 17.4753 6.6013C17.292 4.5763 15.9503 3.19297 13.9587 2.95964C13.7587 2.9263 13.5503 2.91797 13.3337 2.91797H5.83366C5.60033 2.91797 5.37533 2.93464 5.15866 2.96797C3.03366 3.23464 1.66699 4.81797 1.66699 7.08464V12.918C1.66699 15.218 3.53366 17.0846 5.83366 17.0846H13.3337C15.667 17.0846 17.2753 15.6263 17.4753 13.4013C17.5003 13.1596 17.3003 12.9596 17.0587 12.9596ZM10.8337 8.1263H5.83366C5.49199 8.1263 5.20866 7.84297 5.20866 7.5013C5.20866 7.15964 5.49199 6.8763 5.83366 6.8763H10.8337C11.1753 6.8763 11.4587 7.15964 11.4587 7.5013C11.4587 7.84297 11.1753 8.1263 10.8337 8.1263Z"
                            fill="#3749E9"
                          />
                        </svg>{' '}
                        My NFTs
                      </a>
                    </Tab>
                    {/* <Tab>
                      <a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            className="svg-fill"
                            d="M14.4916 13.0583L11.1249 10H8.86657L5.4999 13.0583C4.55823 13.9083 4.2499 15.2167 4.70823 16.4C5.16657 17.575 6.28323 18.3333 7.54157 18.3333H12.4499C13.7166 18.3333 14.8249 17.575 15.2832 16.4C15.7416 15.2167 15.4332 13.9083 14.4916 13.0583ZM11.5166 15.1167H8.48323C8.16657 15.1167 7.91657 14.8583 7.91657 14.55C7.91657 14.2417 8.1749 13.9833 8.48323 13.9833H11.5166C11.8332 13.9833 12.0832 14.2417 12.0832 14.55C12.0832 14.8583 11.8249 15.1167 11.5166 15.1167Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M15.2919 3.6013C14.8335 2.4263 13.7169 1.66797 12.4585 1.66797H7.54188C6.28355 1.66797 5.16688 2.4263 4.70855 3.6013C4.25855 4.78464 4.56688 6.09297 5.50855 6.94297L8.87521 10.0013H11.1335L14.5002 6.94297C15.4335 6.09297 15.7419 4.78464 15.2919 3.6013ZM11.5169 6.0263H8.48355C8.16688 6.0263 7.91688 5.76797 7.91688 5.45964C7.91688 5.1513 8.17522 4.89297 8.48355 4.89297H11.5169C11.8335 4.89297 12.0835 5.1513 12.0835 5.45964C12.0835 5.76797 11.8252 6.0263 11.5169 6.0263Z"
                            fill="#3749E9"
                          />
                        </svg>{' '}
                        History
                      </a>
                    </Tab> */}
                    <Tab>
                      <a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            className="svg-fill"
                            d="M16.2586 4.8763L11.3086 2.01797C10.5003 1.5513 9.50026 1.5513 8.68359 2.01797L3.74193 4.8763C2.93359 5.34297 2.43359 6.20964 2.43359 7.1513V12.8513C2.43359 13.7846 2.93359 14.6513 3.74193 15.1263L8.69193 17.9846C9.50026 18.4513 10.5003 18.4513 11.3169 17.9846L16.2669 15.1263C17.0753 14.6596 17.5753 13.793 17.5753 12.8513V7.1513C17.5669 6.20964 17.0669 5.3513 16.2586 4.8763ZM10.0003 6.11797C11.0753 6.11797 11.9419 6.98464 11.9419 8.05964C11.9419 9.13464 11.0753 10.0013 10.0003 10.0013C8.92526 10.0013 8.05859 9.13464 8.05859 8.05964C8.05859 6.99297 8.92526 6.11797 10.0003 6.11797ZM12.2336 13.8846H7.76693C7.09193 13.8846 6.70026 13.1346 7.07526 12.5763C7.64193 11.7346 8.74193 11.168 10.0003 11.168C11.2586 11.168 12.3586 11.7346 12.9253 12.5763C13.3003 13.1263 12.9003 13.8846 12.2336 13.8846Z"
                            fill="#3749E9"
                          />
                        </svg>{' '}
                        Account Setting
                      </a>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 overflow-table">
                <div className="dashboard-content inventory content-tab">
                  <TabPanel>
                    <div className="inner-content collection">
                      <h4 className="title-dashboard">My Collections</h4>
                      <div className="row tf-filter-container wow fadeInUp">
                        {myCollections?.map((idx) => (
                          <div key={idx.id} className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d pixel">
                            <div
                              className="sc-product style3"
                              onClick={() => navigate(`/collection/${idx?.collectionId}`)}
                            >
                              <div className="features">
                                <div className="product-media">
                                  <img src={idx.featuredImg} alt="images" />
                                </div>
                              </div>
                              <div className="content">
                                <div className="details-product">
                                  <div className="title">
                                    <Link to="/item-details">{idx.name}</Link>
                                  </div>
                                  <div className="creator">
                                    <Link to="#">{shortAddress(idx?.authority)}</Link>
                                  </div>
                                </div>
                                {/* <div className="price" style={{minWidth: '140px'}}>
                                  <div className="subtitle">Total Volume</div>
                                  <div className="cash d-flex align-items-center justify-content-end">
                                    99,999.88 <SUISvg color="white" />
                                  </div>
                                </div> */}
                                <div className="profile-author">
                                  <div className="rounded-circle" style={{width: '36px', height: '36px'}}>
                                    <img
                                      src={idx.avatarImg}
                                      alt="avatarImg"
                                      className="avatar rounded-circle border-white border-1"
                                    />
                                  </div>
                                  {/* <Link to="#" className="avatar" data-tooltip="Creator: Daniel Rose" tabIndex="0">
                                <img src={idx.avt2} alt="images" />
                              </Link>
                              <Link to="#" className="avatar" data-tooltip="Creator: Solvador" tabIndex="0">
                                <img src={idx.avt3} alt="images" />
                              </Link> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="inner-content wallet">
                      <h4 className="title-dashboard">My NFTs</h4>
                      <div className="row tf-filter-container wow fadeInUp">
                        {myNFTs?.map((idx) => (
                          <div
                            key={idx.id}
                            onClick={() => navigate(`/nft/${idx?.nftId}`)}
                            className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                          >
                            <div className="sc-product style2">
                              <div className="top">
                                <Link to="/item-details-v1" className="tag">
                                  {idx.name}
                                </Link>
                                {/* <div className="wish-list">
                              <Link to="#" className="heart-icon"></Link>
                            </div> */}
                              </div>
                              <div className="bottom">
                                <div className="details-product">
                                  <div className="author mt-4">
                                    <div className="avatar rounded-circle">
                                      <img
                                        className="rounded-circle mt-1"
                                        src={idx?.ownerObject?.dataObject?.avatarImg}
                                        alt="images"
                                      />
                                    </div>
                                    <div className="content">
                                      <div className="position">Owner</div>
                                      <div className="name">
                                        {' '}
                                        <Link to="#">{shortAddress(idx?.nftOwner) || idx?.collectionId}</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="features">
                                <div className="product-media">
                                  <img src={idx.url} alt="nft" />
                                </div>
                                {/* <div className="rain-drop1">
                              <img src={icon1} alt="images" />
                            </div>
                            <div className="rain-drop2">
                              <img src={icon2} alt="images" />
                            </div> */}
                              </div>
                              <div className="bottom-style2">
                                <div className="price">
                                  <div className="icon" style={{marginTop: '10px'}}>
                                    {/* <img src={icon3} alt="images" /> */}
                                    <SUISvg />
                                  </div>
                                  <div className="content">
                                    <div className="name">SUI</div>
                                    <div className="cash"> {idx?.saleIndex || 0}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </TabPanel>
                  {/* <TabPanel>
                    <div className="inner-content history">
                      <h4 className="title-dashboard">History</h4>
                      
                    </div>
                  </TabPanel> */}
                  <TabPanel>
                    <div className="inner-content profile">
                      <h4 className="title-dashboard">Edit Profile</h4>
                      <div>
                        {' '}
                        <UploadImage
                          title={'Avatar Image'}
                          descSize=" Recommended file size is 400x400 px"
                          className="col"
                          setFileUpload={(data) => setFileUpload({...data, isEdit: true})}
                          uploadEle={null}
                          fileListProp={
                            userInfo?.dataObject?.avatarImg ? [{url: userInfo?.dataObject?.avatarImg}] : null
                          }
                        />
                      </div>
                      <Form
                        className="form-edit-profile"
                        initialValues={{}}
                        form={form}
                        onFinish={onUpdateProfile}
                        autoComplete="off"
                      >
                        <div className="user-profile">
                          <fieldset>
                            <h6>
                              Name<span className="text-red">*</span>
                            </h6>
                            <Form.Item
                              className="form-item"
                              name="name"
                              rules={[{required: true, message: 'Please input your name!'}]}
                            >
                              <input type="text" placeholder="Name" />
                            </Form.Item>
                          </fieldset>
                          <fieldset>
                            <h6>
                              Bio<span className="text-red">*</span>
                            </h6>
                            <Form.Item
                              className="form-item"
                              name="description"
                              rules={[{required: true, message: 'Please input your Bio!'}]}
                            >
                              <textarea allowClear placeholder="Your Bio" />
                            </Form.Item>
                          </fieldset>
                        </div>
                        <div className="row user-profile">
                          <fieldset className="col-6">
                            <h6>Telegram URL</h6>
                            <Form.Item
                              className="form-item"
                              name="telegramUrl"
                              rules={[
                                ({getFieldValue}) => ({
                                  validator(_, value) {
                                    if (value && !isValidWebsite(value))
                                      return Promise.reject(new Error('Please enter your valid URL!'));
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <input type="text" placeholder="https://" />
                            </Form.Item>
                          </fieldset>
                          <fieldset className="col-6">
                            <h6>Twitter URL</h6>
                            <Form.Item
                              className="form-item"
                              name="twitterUrl"
                              rules={[
                                ({getFieldValue}) => ({
                                  validator(_, value) {
                                    if (value && !isValidWebsite(value))
                                      return Promise.reject(new Error('Please enter your valid URL!'));
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <input type="text" placeholder="https://" />
                            </Form.Item>
                          </fieldset>
                          <fieldset className="col-6">
                            <h6>Discord URL</h6>

                            <Form.Item
                              className="form-item"
                              name="discordUrl"
                              rules={[
                                ({getFieldValue}) => ({
                                  validator(_, value) {
                                    if (value && !isValidWebsite(value))
                                      return Promise.reject(new Error('Please enter your valid URL!'));
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <input type="text" placeholder="https://" />
                            </Form.Item>
                          </fieldset>
                          <fieldset className="col-6">
                            <h6>Instagram URL</h6>

                            <Form.Item
                              className="form-item"
                              name="instagramUrl"
                              rules={[
                                ({getFieldValue}) => ({
                                  validator(_, value) {
                                    if (value && !isValidWebsite(value))
                                      return Promise.reject(new Error('Please enter your valid URL!'));
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <input type="text" placeholder="https://" />
                            </Form.Item>
                          </fieldset>
                        </div>
                        <button className="btn-form" type="submit">
                          Update Settings
                        </button>
                      </Form>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </section>
    </div>
  );
}

export default ProfilePage;

const SUISvg = ({color}) => (
  <svg fill="currentColor" viewBox="0 0 26 40" style={{color: '#9448F0', height: '14px', width: '14px'}}>
    <path
      style={{fill: color, fillRule: 'evenodd'}}
      d="M1.8611,33.0541a13.6477,13.6477,0,0,0,23.7778,0,13.89,13.89,0,0,0,0-13.8909L15.1824.8368a1.6444,1.6444,0,0,0-2.8648,0L1.8611,19.1632A13.89,13.89,0,0,0,1.8611,33.0541ZM10.8044,9.5555,13.0338,5.648a.8222.8222,0,0,1,1.4324,0L23.043,20.68a10.8426,10.8426,0,0,1,.8873,8.8828,9.4254,9.4254,0,0,0-.4388-1.4586c-1.1847-3.0254-3.8634-5.36-7.9633-6.9393-2.8187-1.0819-4.618-2.6731-5.3491-4.73C9.2375,13.7848,10.221,10.8942,10.8044,9.5555ZM7.0028,16.2184,4.457,20.68a10.8569,10.8569,0,0,0,0,10.8582,10.6776,10.6776,0,0,0,16.1566,2.935,7.5061,7.5061,0,0,0,.0667-5.2913c-.87-2.1858-2.9646-3.9308-6.2252-5.1876-3.6857-1.4147-6.08-3.6233-7.1157-6.5625A9.297,9.297,0,0,1,7.0028,16.2184Z"
    />
  </svg>
);
