import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';

import img1 from '../assets/images/collection/banner-collection.jpg';
import img2 from '../assets/images/collection/img-banner-collection.png';
import {Link, useParams} from 'react-router-dom';
import HotPick4 from '../components/hotpick/HotPick4';
import dataHotpick from '../assets/fake-data/data-hotpick';
import {message} from 'antd';
import API from '@/hooks/api/useCallAPI';
import {useQuery} from '@tanstack/react-query';
import {shortAddress} from '@/utils/utils';
import {useNFTsQuery} from '@/hooks';

Collection.propTypes = {};

function Collection(props) {
  const params = useParams();

  const {collectionId} = params;

  const fetchCollectionByCollectionID = async ({collectionId: string}) => {
    try {
      const {data} = await API.get(`/nft-collections/collectionId/${collectionId}`, {});

      return data[0];
    } catch (error) {
      message.error('Error when fetching collection!');
    }
  };

  const {data} = useQuery(['fetchCollectionByCollectionID'], () => fetchCollectionByCollectionID({collectionId}));

  let imageUrl = useMemo(() => data?.data && JSON.parse(data?.data), [data]);

  const fallbackUrl = 'https://dragonsui.s3.amazonaws.com/1440.webp';

  const {data: NFTListData} = useNFTsQuery();

  const NFTList = NFTListData?.filter((item) => item.collectionId === collectionId);
  return (
    <div className="page-collection">
      <PageTitle none={'none'} title={data?.name || 'Collection'} />

      <section className="tf-banner-collection">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner-collection-inner">
                <img
                  src={imageUrl?.bannerImg}
                  alt="bannerImg"
                  className="img-bg"
                  style={{maxHeight: '322px', display: 'block', margin: '0 auto'}}
                />
                <img
                  src={imageUrl?.avatarImg}
                  alt="avatarImg"
                  className="img-banner"
                  style={{width: '90px', height: '90px'}}
                />
                <div className="button-top">
                  {/* <Link to="#" className="btn-wishlish">
                    <i className="fas fa-heart"></i> Add to Wishlist
                  </Link> */}
                  <a
                    href={`${process.env.REACT_APP_EXPLORER_SUI_LINK}object/${collectionId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-collect"
                  >
                    <i className="fas fa-long-arrow-right"></i>
                  </a>
                  {/* <div className="btn-option">
                    <i className="fas fa-ellipsis-h"></i>
                    <div className="option_popup">
                      <Link to="#">Delete</Link>
                      <Link to="#">Edit</Link>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="tf-heading style-5">
                <h2 className="heading">{data?.name?.replaceAll('_', ' ')}</h2>
                <p className="sub-heading">{shortAddress(data?.authority)}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HotPick4 data={NFTList} authority={data?.authority} {...imageUrl} />
    </div>
  );
}

export default Collection;
