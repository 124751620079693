import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {SuiObjectName} from '@/utils/constant';
import {Button, Menu, Modal} from 'antd';
import React, {useMemo, useState} from 'react';
import {LogoutOutlined, UserOutlined} from '@ant-design/icons';
import './style.scss';
import {shortAddress} from '@/utils/utils';
import {convertBalance} from '@/utils/SuiObject';
import {Link, NavLink} from 'react-router-dom';
import {ConnectModal, useWallet} from '@suiet/wallet-kit';
import {useUIContext} from '@/contexts/AppContext';
import useWindowDimensions from '@/hooks/useWindowDimensions';

export const LoginModal = ({
  children,
  className,
  size,
  menuActive,
  handleMenuActive,
  activeIndex,
  handleDropdown,
  ...props
}: any) => {
  const [open, setOpen] = useState(false);
  const {onConnectClick, addressWallet, balanceWallet} = useBlockchainContext();
  const {isModalConnectOpen, setIsModalConnectOpen} = useUIContext();
  const handleClickOpen = () => {
    setIsModalConnectOpen(true);
    handleMenuActive();
  };

  const {width} = useWindowDimensions();

  const isMobile = useMemo(() => width < 600, [width]);

  const handleClose = () => {
    setOpen(false);
  };

  const {wallet, select, connecting, connected, disconnect} = useWallet();
  const wallets = [];
  const listMenu = useMemo(() => {
    return [
      {name: 'My Profile', icon: <UserOutlined />, link: '/profile'},
      {name: 'Sign Out', icon: <LogoutOutlined />, event: disconnect, link: '/'},
    ];
  }, [disconnect]);

  const handleConnect = (walletName: string) => {
    select(walletName);
    handleClose();
  };

  return (
    <ConnectModal
      open={isModalConnectOpen}
      onOpenChange={(open) => {
        setIsModalConnectOpen(open);
      }}
    >
      <>
        {!connected ? (
          <>
            <button className="tf-button " onClick={handleClickOpen}>
              <span>Connect Wallet</span>
            </button>
          </>
        ) : !isMobile ? (
          <div>
            <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
              <ul id="menu-primary-menu" className="menu">
                {
                  <li
                    key={'user'}
                    onClick={() => handleDropdown('user')}
                    className={`menu-item menu-item-has-children'} ${activeIndex === 'user' ? 'active' : ''}`}
                  >
                    <Link to={'profile'}>{shortAddress(addressWallet)}</Link>
                    {
                      <ul className="sub-menu">
                        {listMenu.map((submenu) => (
                          <li key={submenu.name} className="menu-item">
                            <NavLink to={submenu.link} onClick={() => submenu?.event()}>
                              {submenu.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    }
                  </li>
                }
              </ul>
            </nav>
          </div>
        ) : (
          <li
            key={'user'}
            onClick={() => handleDropdown('user')}
            className={`menu-item menu-item-has-children'} ${activeIndex === 'user' ? 'active' : ''}`}
          >
            <Link to={''}>{shortAddress(addressWallet)}</Link>
            {
              <ul className="sub-menu">
                {listMenu.map((submenu) => (
                  <li key={submenu.name} className="menu-item">
                    <NavLink to={submenu.link} onClick={() => submenu?.event()}>
                      {submenu.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            }
          </li>
        )}
      </>
    </ConnectModal>
  );
};
