import {Fragment} from 'react';
import './style.scss';
import {Router, useNavigate} from 'react-router-dom';

const ComingSoon = (props) => {
  const history = useNavigate();
  return (
    <div className="h-screen coming-soon-page">
      <div className="title-block">
        <div className="title">
          <div className="text-white">great things</div>
          coming soon
        </div>
        <button className="tf-button active" onClick={() => history('/')}>
          go to Staking{' '}
        </button>
      </div>
    </div>
  );
};

export default ComingSoon;
