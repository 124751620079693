import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import API from '@/hooks/api/useCallAPI'

type FetcherParamsT = {
}

const fetcher = async (params: FetcherParamsT) => {
  const rs = await API.get('/nft-collections', params)
  return rs.data.filter(el => !!el);
}

const useCollectionsQuery = (params: FetcherParamsT) => {
  const fn = useQuery(
    ['collections', params],
    () => {
      return fetcher({
        ...params,
      })
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
  return {
    ...fn,
  }
}

export { useCollectionsQuery }
