import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Navigation, Scrollbar, A11y, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import CardModal from '../layouts/CardModal';

import icon1 from '../../assets/images/icon/rain1.svg';
import icon2 from '../../assets/images/icon/rain2.svg';
import icon3 from '../../assets/images/icon/ethe.svg';
import {Link, useNavigate} from 'react-router-dom';

import {useCollectionsQuery} from '@/hooks';
import {shortAddress} from '@/utils/utils';

FeaturedCollection.propTypes = {
  data: PropTypes.array,
};

function FeaturedCollection(props) {
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();
  const {data} = useCollectionsQuery({});

  const collectionList = data?.map((item) => {
    const objectData = JSON.parse(item?.data);

    return {
      ...item,
      ...objectData,
      authority: shortAddress(item.authority),
      name: item?.name?.replaceAll('_', ' '),
    };
  })?.filter(el => el.nftIds?.length > 0);

  return (
    <section className="tf-section tf-live-auction visible-sw">
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-heading mb40 wow fadeInUp" style={{justifyContent: 'center'}}>
              <h4 className="heading">Featured Collections</h4>
              {/* <a className="button" href="/live-auctions-v1">
                Explore<i className="fal fa-long-arrow-right"></i>
              </a> */}
            </div>
          </div>
          <div
            className="col-md-12 wow fadeInUp mb-4"
            style={{overflow: 'hidden', paddingTop: '20px', paddingBottom: '20px'}}
          >
            <Swiper
              modules={[Navigation, Scrollbar, A11y, Pagination]}
              spaceBetween={30}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                767: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 4,
                },
              }}
              className="live-auction visible "
              navigation
              loop={true}
              pagination={{
                clickable: true,
              }}
            >
              {collectionList?.slice(0, 4).map((idx) => (
                <SwiperSlide key={idx.id}>
                  <div className="slider-item">
                    <div className="sc-product style1">
                      <div className="top">
                        <Link to={`/collection/${idx?.collectionId}`} className="tag">
                          {idx.name}
                        </Link>
                        {/* <div className="wish-list">
                          <Link to="#" className= "heart-icon"></Link>
                        </div> */}
                      </div>
                      <Link to={`/collection/${idx?.collectionId}`} className="tag">
                        <div className="features">
                          <div className="product-media" style={{width: '290px', height: '290px'}}>
                            <img
                              src={idx.featuredImg}
                              alt="featuredImg"
                              style={{width: '100%', height: '100%', objectFit: 'cover'}}
                            />
                          </div>
                          <div className="featured-countdown">
                            <span className="js-countdown" data-timer="55555" data-labels=" ,  h , m , s "></span>
                          </div>
                          <div className="rain-drop1">
                            <img src={icon1} alt="images" />
                          </div>
                          <div className="rain-drop2">
                            <img src={icon2} alt="images" />
                          </div>
                        </div>
                      </Link>
                      <div className="bottom">
                        <div className="details-product">
                          <div className="author">
                            <div className="avatar">
                              <img src={idx.avatarImg} alt="avatarImg" style={{height: '40px', width: '40px'}} />
                            </div>
                            <div className="content">
                              <div className="position">Creator</div>
                              <div className="name">
                                <Link to="/item-details-v1">{idx.authority}</Link>
                              </div>
                            </div>
                          </div>
                          {/* <div className="current-bid">
                            <div className="subtitle">Total Volume</div>
                            <div className="price d-flex align-items-center">
                              <span className="cash">99,999.99</span>
                              <SUISvg color="white" />
                            </div>
                          </div> */}
                        </div>
                        {/* <div className="product-button">
                          <Link
                            to={`/collection/${idx?.collectionId}`}
                            data-toggle="modal"
                            data-target="#popup_bid"
                            className="tf-button"
                          >
                            Go to collection
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
}

export default FeaturedCollection;

export const SUISvg = ({color}) => (
  <svg fill="currentColor" viewBox="0 0 24 24" style={{height: '22px', width: '22px'}}>
    <circle cx="12" cy="12" r="12" fill="#9448F0" />
    <path fill="url(#iconA)" d="M6.6 3.6H18v16.2H6.6z" />
    <defs>
      <pattern id="iconA" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#iconB" transform="matrix(.00347 0 0 .00252 -.004 0)" />
      </pattern>
      <image id="iconB" width="758" height="500" />
    </defs>
  </svg>
);
