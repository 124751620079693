

import img1 from '../images/product-category/product-category-1.jpg'
import img2 from '../images/product-category/product-category-2.jpg'
import img3 from '../images/product-category/product-category-3.jpg'
import img4 from '../images/product-category/product-category-5.jpg'
const dataCategory = [
    {
        id: 1,
        img: img1,
        title: 'Digital art',
    },
    {
        id: 2,
        img: img2,
        title: 'Style',
    },
    {
        id: 3,
        img: img3,
        title: 'Music',
    },
    {
        id: 4,
        img: img4,
        title: 'Sport',
    },
    {
        id: 5,
        img: img1,
        title: 'Digital art',
    },

   
]

export default dataCategory;