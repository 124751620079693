import React from 'react';
import PropTypes from 'prop-types';

import {Navigation, Scrollbar, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import img1 from '../../assets/images/slider/bg-slider.png';
import img2 from '../../assets/images/slider/slider-2.png';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss';
import {Link} from 'react-router-dom';
import {useCollectionsQuery} from '@/hooks';
import {shortAddress} from '@/utils/utils';
import {SUISvg} from '../liveautions/FeaturedCollection';

Banner.propTypes = {
  data: PropTypes.array,
};

function Banner(props) {
  const {data} = useCollectionsQuery({});

  const collectionList = data
    ?.sort(() => 0.5 - Math.random())
    ?.map((item) => {
      const objectData = JSON.parse(item?.data);

      return {
        ...item,
        ...objectData,
        authority: shortAddress(item.authority),
        name: item?.name?.replaceAll('_', ' '),
        heading: 'Discover, Create, Collect & Trade NFTs',
        desc: 'Get quick & easy access to digital collectibles, explore & trade NFTs from different collections & artists.',
      };
    })
    ?.slice(0, 1);
  return (
    <section className="tf-slider">
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <Swiper
              modules={[Navigation, Scrollbar, A11y]}
              spaceBetween={0}
              slidesPerView={1}
              className="slider-home home1"
              loop={false}
            >
              {collectionList?.map((idx) => (
                <SwiperSlide key={idx.id}>
                  <div className="tf-slider-item">
                    <div className="content-inner">
                      <h1 className="heading">
                        {idx.heading}
                      </h1>
                      <p className="sub-heading">{idx.desc}</p>
                      <div className="btn-slider ">
                        <Link to="/create-nft" className="tf-button style-2">
                          Create NFT
                        </Link>
                        {/* <Link to="/create" className="tf-button style-3">
                          Creat
                        </Link> */}
                      </div>
                    </div>
                    <div className="image">
                      <div className="img-slider-main ani5 img-col" style={{maxWidth: '450px', maxHeight: '450px'}}>
                        <img src={idx.featuredImg} alt="featuredImg" />
                      </div>
                      {/* <img src={img2} alt="Binasea" className="img-slider-2 ani4" />
                      <img src={img2} alt="Binasea" className="img-slider-3 ani5" /> */}

                      {/* <div className="current-bid ">
                        <div className="title">Total Volume</div>
                        <div className="price d-flex align-items-center">
                          <span className="mr-2">99,999.88</span> <SUISvg />
                        </div>
                      </div> */}
                      <div className="card-infor ani5">
                        {/* <img src={idx.avt} alt="Binasea" /> */}
                        <img style={{maxWidth: '60px'}} class="card-img-top" src={idx.avatarImg} alt="avatarImg" />

                        <div className="inner">
                          <h6 className="name">{idx.name}</h6>
                          <p className="author">{idx.authority}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
