import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PageTitle from '@/components/pagetitle/PageTitle';
import {Link, useParams, useSearchParams} from 'react-router-dom';

import img1 from '@/assets/images/product/product4.jpg';
import nftTag from '@/assets/images/product/NFT_Tag.png';
import {CopyOutlined} from '@ant-design/icons';
import {useFarmQuery} from '@/hooks/farms/useFarmQuery';
import {useNFTFarmQuery} from '@/hooks/farms/useFarmsQuery';
import Countdown, {zeroPad} from 'react-countdown';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {convertBalance, getObjectById} from '@/utils/SuiObject';
import {DRA_TOKEN, SuiObjectName} from '@/utils/constant';
import {shortAddress} from '@/utils/utils';
import {useNFTsQuery} from '@/hooks';
import {useNFTFarmContext} from '@/contexts/NFTFarmProtocolContext';
import { Form } from 'antd';

CreateLaunchpadPage.propTypes = {};

function CreateLaunchpadPage(props) {
  const form = Form.useForm();
  return (
    <div className="create-launchpad-page">
      <PageTitle none="none" title="Create new launchpad" />
      <div className="tf-container mt-5">
        <Form initialValues={{}} form={form}></Form>
        <div className="info-block">
          <h5 className="title-txt">Project Info</h5>
        </div>
      </div>
    </div>
  );
}

export default CreateLaunchpadPage;
