import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PageTitle from '@/components/pagetitle/PageTitle';
import {Link, useNavigate} from 'react-router-dom';
import img from '@/assets/images/background/thumb-pagetitle.jpg';
import avt from '@/assets/images/author/author-db.jpg';

import img1 from '@/assets/images/product/product4.jpg';
import moment from 'moment';
import {useNFTFarmQuery} from '@/hooks/farms/useFarmsQuery';
import Countdown, {zeroPad} from 'react-countdown';

NFTFarmPage.propTypes = {};

function NFTFarmPage(props) {
  const history = useNavigate();
  const goDetail = (poolId) => {
    history(`/nft-pool/${poolId}`);
  };
  const {data: dataNft} = useNFTFarmQuery();
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const dataNftMap = useMemo(
    () =>
      dataNft
        ?.filter((ele) => !!ele?.dataObject?.nftFarmPool)
        ?.map((el) => ({...el, ...JSON.parse(el.collectionObject.data)})),
    [dataNft]
  );

  return (
    <div className="nft-farm-page">
      <PageTitle none="none" title="NFT Yield Farms" />
      <div className="tf-container pt-3">Stake NFT to earn more DRA</div>
      <section className="dashboard-content inventory content-tab">
        <div className="tf-container">
          <div className="inner-content inventory">
            <div className="table-ranking top">
              <div className="title-ranking">
                <div className="col-rankingg">
                  <div className="category-title">Stake</div>
                </div>
                <div className="col-rankingg">
                  <div className="category-title">Earn</div>
                </div>
                <div className="col-rankingg">
                  <div className="category-title">TVL</div>
                </div>
                <div className="col-rankingg">
                  <div className="category-title">Reward Pool</div>
                </div>
                <div className="col-rankingg">
                  <div className="category-title">Multiplier</div>
                </div>
                <div className="col-rankingg">
                  <div className="category-title">Expired In</div>
                </div>
                <div className="col-rankingg">
                  <div className="category-title">My Stake</div>
                </div>
              </div>
            </div>
            <div className="table-ranking ">
              {dataNftMap?.map((el) => (
                <div className="content-ranking" onClick={() => goDetail(el.id)}>
                  <div className="col-rankingg d-flex align-items-center">
                    <div className="image">
                      <img src={el?.avatarImg || img1} alt="Binasea" />
                    </div>
                    <div className="ml-3">{el.collectionObject.name}</div>
                  </div>
                  <div className="col-rankingg">DRA</div>
                  <div className="col-rankingg">2 NFTs</div>
                  <div className="col-rankingg">{el.tokenReward}</div>
                  <div className="col-rankingg">{el.multiplier}</div>
                  <div className="col-rankingg">
                    <CustomCountdown date={addDays(el.startTime, el.poolLength)} />
                  </div>
                  <div className="col-rankingg"> </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NFTFarmPage;

const CustomCountdown = ({date}) => {
  return (
    <Countdown
      date={date}
      daysInHours={false}
      renderer={({days, hours, minutes, seconds, completed}) => {
        if (completed || date === 'N/A') {
          return (
            <div className="sc-product d-flex justify-content-center mx-2 px-3">
              <div className="" style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '24px'}}>
                N/A
              </div>
            </div>
          );
        } else {
          return (
            <div className="d-flex ">
              <div>
                <div className="mr-2">{zeroPad(days)}d</div>
              </div>
              <div>
                <div className="mr-2">{zeroPad(hours)}h</div>
              </div>

              <div>
                <div className="mr-2">{zeroPad(minutes)}m</div>
              </div>
              <div>
                <div className="mr-2">{zeroPad(seconds)}s</div>
              </div>
            </div>
          );
        }
      }}
    />
  );
};
