import React, {useMemo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import PageTitle from '@/components/pagetitle/PageTitle';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Link, useNavigate} from 'react-router-dom';
import icon1 from '@/assets/images/svg/metamask.svg';
import icon2 from '@/assets/images/svg/coinbase.svg';
import icon3 from '@/assets/images/svg/torus.svg';
import icon4 from '@/assets/images/svg/fortmatic.svg';
import icon5 from '@/assets/images/svg/drap-upload.svg';
import img1 from '@/assets/images/collection/add-collection.jpg';
import img from '@/assets/images/product/product4.jpg';
import ico1 from '@/assets/images/icon/rain1.svg';
import ico2 from '@/assets/images/icon/rain2.svg';
import ico3 from '@/assets/images/icon/ethe.svg';
import avt from '@/assets/images/author/author1.png';
import {Form, message} from 'antd';
import './style.scss';
import {DeleteOutlined} from '@ant-design/icons';
import {UploadImage} from '@/components/UploadImage';
import {convertu64, shortAddress} from '@/utils/utils';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {CreateCollectionModal} from '@/components/CreateCollection';
import {useCollectionsQuery, useCreateNFTMutation} from '@/hooks';
import {useUIContext} from '@/contexts/AppContext';
import {convertBalanceNumber, fetchObjectByIds, getID} from '@/utils/SuiObject';
import {useNFTProtocolContext} from '@/contexts/NFTProtocolContext';
import {SuiObjectName} from '@/utils/constant';

CreateNftPage.propTypes = {};

function CreateNftPage(props) {
  const [form] = Form.useForm();
  const [fileUpload, setFileUpload] = useState({});
  const [isOpenCreateCollection, setIsOpenCreateCollection] = useState(false);
  const [collectionSelected, setCollectionSelected] = useState({});
  const [listInventory, setListInventory] = useState([]);
  const {addressWallet, mintNft, getAllObjectOfAddress} = useBlockchainContext();
  const {initInventory, initInventoryAndListing} = useNFTProtocolContext();
  const {checkAuth} = useUIContext();
  const nameValue = Form.useWatch('name', form);

  const {data: collections, refetch: fetchCollection} = useCollectionsQuery({});

  const {mutateAsync: createNftMutation, isLoading: isLoadingCreate} = useCreateNFTMutation();

  const myCollections = useMemo(() => {
    const listCol = collections
      ?.filter((ele) => ele?.authority === addressWallet)
      .map((el) => ({...el, data: JSON.parse(el.data)}));
    return listCol;
  }, [collections, addressWallet]);

  const handleCancelCreateCollection = () => {
    fetchCollection();
    setIsOpenCreateCollection(false);
  };

  const history = useNavigate();

  const validateCreateNft = (values) => {
    if (!fileUpload?.location) {
      message.warning('Please upload NFT image!');
      return false;
    }
    if (!collectionSelected?.dataObject) {
      message.warning('Please select collection');
      return false;
    }
    return true;
  };

  const getListObjectByName = (name) => {
    return collectionSelected?.dataObject?.ret?.ids?.listCollectionId.filter((el) => el.name === name);
  };

  const getMarketId = () => {
    return collectionSelected?.dataListAndMarketObject?.effects?.events.filter((el) =>
      el?.newObject?.objectType?.includes('::fixed_price::FixedPriceMarket')
    );
  };

  useEffect(() => {
    const markets = getMarketId();
    console.log(markets, '');
  }, [collectionSelected]);

  // const getPriceInventory = async () => {
  //   const inventories = getListObjectByName("Inventory")
  //   if(!inventories) return
  //   const invenIds= inventories.map(el => el.id)
  //   setListInventory(invenIds)
  //   const objects = await fetchObjectByIds(invenIds)
  //   const marketIds = objects.map(el => el.data?.fields?.live?.fields?.contents?.[0]?.fields?.key)
  //   console.log(marketIds, 'marketIds', objects);
  //   const objectsMarket = await fetchObjectByIds(marketIds)
  //  console.log(objectsMarket, 'objects');
  // }

  // const getInventory = () => {
  //   return collectionSelected?.dataListAndMarketObject?.effects?.events.filter((el) =>
  //     el?.newObject?.objectType?.includes('::inventory::Inventory')
  //   );
  // };

  const getInventory = async () => {
    const objects = await getAllObjectOfAddress(addressWallet);
    const inventory = objects.find((el) => el?.data?.type === SuiObjectName.inventory);
    console.log(inventory, objects, 'objects');
    if (inventory) {
      return getID(inventory.data);
    }
    const res = await initInventory();
    console.log(res);
    return res?.effects?.created?.[0]?.reference?.objectId;
  };

  const initInventoryList = async () => {
    const res = await initInventoryAndListing(`${collectionSelected?.collectionId}::${collectionSelected?.name}`);
    console.log(res);
    const listing = res?.effects?.events.find((el) => el?.newObject?.objectType?.includes('::listing::Listing'));
    const inventory = res?.effects?.events.find((el) => el?.newObject?.objectType?.includes('::inventory::Inventory'));
    return {listingId: listing?.newObject?.objectId, inventoryId: inventory?.newObject?.objectId};
  };

  const createNFT = async (values) => {
    if (!checkAuth()) return;
    if (!validateCreateNft(values)) return;
    const {listingId, inventoryId} = await initInventoryList();
    message.info('Creating NFT');
    const attributeKeys = [];
    const attributeValues = [];
    values?.properties?.map((el) => {
      attributeKeys.push(el.type);
      attributeValues.push(el.name);
    });

    values?.level?.map((el) => {
      attributeKeys.push(el.name);
      attributeValues.push(`${el.level1}/${el.level2}`);
    });
    const tokenMintCap = getListObjectByName('tokenMintCap');
    const res = await mintNft(
      [
        values?.name,
        values?.description,
        fileUpload.location,
        attributeKeys,
        attributeValues,
        tokenMintCap[0].id,
        inventoryId,
      ],
      collectionSelected
    );
    console.log(res, 'res');
    const markets = getMarketId();
    const res2 = await createNftMutation({
      type: 'Classic',
      name: values?.name,
      description: values?.description,
      collectionId: collectionSelected?.collectionId,
      url: fileUpload.location,
      attributeKeys,
      attributeValues,
      saleIndex: 0,
      nftOwner: addressWallet,
      nftId: res?.effects.created?.[0]?.reference?.objectId,
      launchpadId: '',
      dataObject: {
        listingId,
        inventoryId,
        tokenMintCap: tokenMintCap[0].id,
      },
    });

    history(`/nft/${res?.effects.created?.[0]?.reference?.objectId}`);
  };

  const selectCollection = (collection) => {
    // if (collection?.nftIds?.length >= collection?.maxSupply) {
    //   message.info(`You have created max supply ${collection?.maxSupply} of NFTs for this collection.`);
    //   return
    // }
    setCollectionSelected(collection);
  };

  return (
    <div>
      <PageTitle none="none" title="Create NFT" />

      <section className="tf-add-nft">
        <div className="tf-container">
          <div className="row ">
            <div className="col-xl-9 col-lg-8 ">
              <div className="add-nft-inner">
                {/* <h6 className="title">Select Item Type</h6>
                <p className="sub">They All Serve The Same Purpose, But Each One Takes.</p> */}

                <Tabs className="tf-tab">
                  {/* <TabList className="create-button menu-tab">
                    <Tab>
                      <div className="create-item">
                        <div className="icon">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM4.47 5.72C4.76 5.43 5.24 5.43 5.53 5.72C6.24 6.43 7.4 6.43 8.11 5.72C8.4 5.43 8.88 5.43 9.17 5.72C9.46 6.01 9.46 6.49 9.17 6.78C8.52 7.43 7.67 7.75 6.82 7.75C5.97 7.75 5.12 7.43 4.47 6.78C4.18 6.48 4.18 6.01 4.47 5.72ZM10 16.78C7.31 16.78 5.12 14.59 5.12 11.9C5.12 11.2 5.69 10.62 6.39 10.62H13.59C14.29 10.62 14.86 11.19 14.86 11.9C14.88 14.59 12.69 16.78 10 16.78ZM15.53 6.78C14.88 7.43 14.03 7.75 13.18 7.75C12.33 7.75 11.48 7.43 10.83 6.78C10.54 6.49 10.54 6.01 10.83 5.72C11.12 5.43 11.6 5.43 11.89 5.72C12.6 6.43 13.76 6.43 14.47 5.72C14.76 5.43 15.24 5.43 15.53 5.72C15.82 6.01 15.82 6.48 15.53 6.78Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <span>Create single item</span>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="create-item">
                        <div className="icon">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.748 1H4.74805C3.08805 1 1.74805 2.34 1.74805 4V10C1.74805 11.66 3.08805 13 4.74805 13H9.24805C9.24805 10.52 11.268 8.5 13.748 8.5V4C13.748 2.34 12.408 1 10.748 1ZM4.51805 4.27C5.28805 3.72 6.33805 3.71 7.12805 4.25C7.46805 4.48 7.55805 4.95 7.32805 5.29C7.09805 5.63 6.62805 5.72 6.28805 5.49C6.01805 5.3 5.64805 5.3 5.37805 5.5C5.24805 5.59 5.09805 5.63 4.94805 5.63C4.71805 5.63 4.47805 5.52 4.33805 5.32C4.09805 4.98 4.17805 4.51 4.51805 4.27ZM10.018 8.24C9.75805 8.57 9.28805 8.62 8.96805 8.36C8.61805 8.08 8.19805 7.93 7.74805 7.93C6.74805 7.93 5.91805 8.69 5.80805 9.67H7.90805C8.31805 9.67 8.65805 10.01 8.65805 10.42C8.65805 10.83 8.31805 11.17 7.90805 11.17H5.58805C4.87805 11.17 4.29805 10.59 4.29805 9.88C4.29805 7.98 5.84805 6.43 7.74805 6.43C8.52805 6.43 9.29805 6.7 9.90805 7.19C10.228 7.45 10.278 7.92 10.018 8.24ZM11.328 5.29C11.098 5.63 10.628 5.72 10.288 5.49C10.018 5.3 9.64805 5.3 9.37805 5.5C9.24805 5.59 9.09805 5.63 8.94805 5.63C8.71805 5.63 8.47805 5.52 8.33805 5.32C8.09805 4.98 8.17805 4.51 8.51805 4.27C9.28805 3.72 10.338 3.71 11.128 4.25C11.468 4.48 11.558 4.95 11.328 5.29Z"
                              fill="white"
                            />
                            <path
                              d="M18.9678 16.6786C18.8978 16.5986 18.7978 16.5586 18.6878 16.5586H14.8078C14.6978 16.5586 14.5978 16.5986 14.5278 16.6786C14.4578 16.7586 14.4178 16.8686 14.4378 16.9686C14.5678 18.1486 15.5578 19.0486 16.7478 19.0486C17.9378 19.0486 18.9278 18.1586 19.0578 16.9686C19.0678 16.8586 19.0378 16.7586 18.9678 16.6786Z"
                              fill="white"
                            />
                            <path
                              d="M19.748 10H13.748C12.098 10 10.748 11.35 10.748 13V19C10.748 20.65 12.098 22 13.748 22H19.748C21.398 22 22.748 20.65 22.748 19V13C22.748 11.35 21.398 10 19.748 10ZM13.338 13.17C13.578 12.83 14.048 12.75 14.388 12.99C14.658 13.18 15.018 13.18 15.288 13C15.628 12.76 16.098 12.85 16.328 13.2C16.558 13.54 16.478 14.01 16.128 14.24C15.738 14.5 15.288 14.64 14.838 14.64C14.368 14.64 13.908 14.5 13.518 14.22C13.178 13.97 13.098 13.5 13.338 13.17ZM16.748 20.17C14.848 20.17 13.298 18.62 13.298 16.72C13.298 16.01 13.878 15.43 14.588 15.43H18.908C19.618 15.43 20.198 16.01 20.198 16.72C20.198 18.62 18.648 20.17 16.748 20.17ZM20.128 14.23C19.738 14.49 19.288 14.63 18.838 14.63C18.368 14.63 17.908 14.49 17.518 14.21C17.178 13.97 17.098 13.5 17.338 13.16C17.578 12.82 18.048 12.74 18.388 12.98C18.658 13.17 19.018 13.17 19.288 12.99C19.628 12.75 20.098 12.84 20.328 13.19C20.558 13.54 20.468 14 20.128 14.23Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <span>Create collection</span>
                      </div>
                    </Tab>
                  </TabList> */}

                  <TabPanel>
                    <Form className="col-span-2" initialValues={{}} form={form} onFinish={createNFT} autoComplete="off">
                      <div className="tab-create-item">
                        <h6 className="title">Upload NFT Image</h6>
                        <UploadImage
                          setFileUpload={setFileUpload}
                          uploadEle={
                            <div className="drag-upload">
                              <img src={icon5} alt="Binasea" />
                              <h6 className="title">Drag your item to upload</h6>
                              <p className="sub-title">JPG, PNG, GIF, WEBP</p>
                            </div>
                          }
                        />

                        <fieldset>
                          <label>Name your NFT</label>

                          <Form.Item
                            className="form-item"
                            name="name"
                            rules={[{required: true, message: 'Please input NFT Name!'}]}
                          >
                            <input type="text" placeholder="E.G. Redeemable  T-Shirt With Logo " />
                          </Form.Item>
                        </fieldset>

                        <fieldset>
                          <label>Enter short description</label>

                          <Form.Item
                            className="form-item"
                            name="description"
                            rules={[{required: true, message: 'Please input NFT description!'}]}
                          >
                            <input type="text" placeholder="E.G. After Purchase You Will Get A  T-Shirt" />
                          </Form.Item>
                        </fieldset>
                        <fieldset className="propertise">
                          <h6 className="title">Traits</h6>
                          <Form.List name="properties">
                            {(fields, {add, remove}) => (
                              <>
                                {fields.map(({key, name, ...restField}) => (
                                  <div key={key} className="property-block">
                                    <div className="item-property">
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'type']}
                                        rules={[{required: true, message: 'Missing type'}]}
                                      >
                                        <input type="text" placeholder="Type" />
                                      </Form.Item>
                                    </div>
                                    <div>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        rules={[{required: true, message: 'Missing name'}]}
                                      >
                                        <input type="text" placeholder="Name" />
                                      </Form.Item>
                                    </div>
                                    <div className="icon-delete ">
                                      <i className="fal fa-times" onClick={() => remove(name)}></i>
                                    </div>
                                  </div>
                                ))}
                                <div className="tf-button btn-add-more" onClick={() => add()}>
                                  Add More
                                </div>
                              </>
                            )}
                          </Form.List>
                        </fieldset>
                        {/* 
                        <div className="set-item">
                          <fieldset>
                            <label className="mb8">Set item price or starting bid</label>
                            <input type="text" placeholder="E.G. 0,01 Eth" />
                          </fieldset>
                          <fieldset>
                            <label className="mb8">Select royalties amount, %</label>
                            <input type="text" placeholder="E.G. 0,01 Eth" />
                          </fieldset>
                        </div> */}

                        <h6 className="title ">Choose Collection</h6>

                        <ul className="create-collection">
                          <li>
                            <div className="create-item" onClick={() => setIsOpenCreateCollection(true)}>
                              <div className="img">
                                <i className="fal fa-plus"></i>
                              </div>
                              <div className="content">
                                <h6>Create new collection</h6>
                                <p>Click to create</p>
                              </div>
                            </div>
                          </li>
                          {myCollections?.map((el) => (
                            <li className={`${collectionSelected?.id === el?.id ? 'active' : ''}`}>
                              <div className="create-item" onClick={() => selectCollection(el)}>
                                <div className="img">
                                  <img className="h-100 rounded-0" src={el?.data?.avatarImg || img1} alt="Binasea" />
                                </div>
                                <div className="content">
                                  <h6>{el?.name}</h6>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>

                        <div className="bottom-button">
                          <Form.Item>
                            <button htmlType="submit" className="tf-button active">
                              Publish
                            </button>
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                  </TabPanel>

                  <TabPanel>
                    <div className="tab-create-collection">
                      <h6 className="title">Upload An Item</h6>
                      <p className="sub">But Each One Takes A Different Approach And Makes Different Tradeoffs.</p>

                      <div className="drag-upload">
                        <input type="file" />
                        <img src={icon5} alt="Binasea" />
                        <h6 className="title">Drag your item to upload</h6>
                        <p className="sub-title">PNG, GIF, WebP, MP4 Or MP3. Maximum File Size 100 Mb.</p>
                      </div>

                      <h6 className="title">Upload An Item</h6>
                      <p className="sub mb22">But Each One Takes A Different Approach And Makes Different Tradeoffs.</p>

                      <fieldset>
                        <label>Name your item</label>
                        <input type="text" placeholder="E.G. Redeemable  T-Shirt With Logo " />
                      </fieldset>

                      <fieldset>
                        <label>Enter short description</label>
                        <input type="text" placeholder="E.G. After Purchase You Will Get A  T-Shirt" />
                      </fieldset>

                      <fieldset className="propertise">
                        <label className="mb8">Add properties</label>
                        <ul className="propertise-list">
                          <li>
                            <Link to="#">
                              Art<i className="fal fa-times"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Body type<i className="fal fa-times"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Face color<i className="fal fa-times"></i>
                            </Link>
                          </li>
                        </ul>
                      </fieldset>

                      {/* <div className="set-item">
                        <fieldset>
                          <label className="mb8">Set item price or starting bid</label>
                          <input type="text" placeholder="E.G. 0,01 Eth" />
                        </fieldset>
                        <fieldset>
                          <label className="mb8">Select royalties amount, %</label>
                          <input type="text" placeholder="E.G. 0,01 Eth" />
                        </fieldset>
                      </div> */}

                      <h6 className="title ">Choose Collection</h6>
                      <p className="sub">They All Serve The Same Purpose.</p>

                      <ul className="create-collection">
                        <li className="active">
                          <div className="create-item">
                            <div className="img">
                              <i className="fal fa-plus"></i>
                            </div>
                            <div className="content">
                              <h6>Create new collection</h6>
                              <p>Type to create</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-item">
                            <div className="img">
                              <img src={img1} alt="Binasea" />
                            </div>
                            <div className="content">
                              <h6>Battle for Digital</h6>
                              <p>56 items</p>
                            </div>
                          </div>
                        </li>
                      </ul>

                      <h6 className="title mb0">Choose collection</h6>
                      <p className="sub mb20">They all serve the same purpose.</p>

                      <ul className="collection-list">
                        <li>
                          <div className="list">
                            <div className="infor">
                              <p>Product updates</p>
                              <h6>Receive messages from our platform</h6>
                            </div>

                            <div className="button-toggle">
                              <input type="checkbox" id="switch4" />
                              <label htmlFor="switch4"></label>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="list">
                            <div className="infor">
                              <p>Reminders</p>
                              <h6>Receive booking reminders, pricing notices</h6>
                            </div>
                            <div className="button-toggle mt0">
                              <input type="checkbox" id="switch5" />
                              <label htmlFor="switch5"></label>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="list">
                            <div className="infor">
                              <p>Promotions and tips</p>
                              <h6>Receive coupons, promotions, surveys</h6>
                            </div>
                            <div className="button-toggle">
                              <input type="checkbox" id="switch6" />
                              <label htmlFor="switch6"></label>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="list">
                            <div className="infor">
                              <p>Account support</p>
                              <h6>Receive messages about your account, your trips, legal alerts</h6>
                            </div>
                            <div className="button-toggle">
                              <input type="checkbox" id="switch7" />
                              <label htmlFor="switch7"></label>
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div className="bottom-button">
                        <Link to="#" className="tf-button active">
                          Publish
                        </Link>
                        <Link to="#" className="tf-button">
                          Discard all
                        </Link>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <h5 className="title-preview">NFT Preview</h5>
              <div className="sc-product style1">
                <div className="top">
                  <Link to="#" className="tag">
                    {nameValue}
                  </Link>
                </div>
                <div className="features">
                  <div className="product-media">
                    <img src={fileUpload?.location || img} alt="images" />
                  </div>
                  <div className="featured-countdown">
                    <span className="js-countdown" data-timer="55555" data-labels=" ,  h , m , s "></span>
                  </div>
                  <div className="rain-drop1">
                    <img src={ico1} alt="images" />
                  </div>
                  <div className="rain-drop2">
                    <img src={ico2} alt="images" />
                  </div>
                </div>
                <div className="bottom">
                  <div className="details-product">
                    <div className="author">
                      <div className="avatar">
                        <img src={avt} alt="images" />
                      </div>
                      <div className="content">
                        <div className="position">Creator</div>
                        <div className="name">
                          <Link to="#">{shortAddress(addressWallet)}</Link>
                        </div>
                      </div>
                    </div>
                    <div className="current-bid">
                      <div className="subtitle">Current Price</div>
                      <div className="price">
                        <span className="cash">
                          {convertBalanceNumber(collectionSelected?.dataObject?.data?.launchpadYaml?.prices[0]) || 0}{' '}
                          SUI
                        </span>
                        <span className="icon">
                          <SUISvg />
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="product-button">
                    <Link to="#" data-toggle="modal" data-target="#popup_bid" className="tf-button">
                      {' '}
                      <span className="icon-btn-product"></span> Place Bid
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CreateCollectionModal isModalOpen={isOpenCreateCollection} handleCancel={handleCancelCreateCollection} />
    </div>
  );
}

export default CreateNftPage;

const SUISvg = ({color}) => (
  <svg fill="currentColor" viewBox="0 0 26 40" style={{color: '#9448F0', height: '14px', width: '14px'}}>
    <path
      style={{fill: color, fillRule: 'evenodd'}}
      d="M1.8611,33.0541a13.6477,13.6477,0,0,0,23.7778,0,13.89,13.89,0,0,0,0-13.8909L15.1824.8368a1.6444,1.6444,0,0,0-2.8648,0L1.8611,19.1632A13.89,13.89,0,0,0,1.8611,33.0541ZM10.8044,9.5555,13.0338,5.648a.8222.8222,0,0,1,1.4324,0L23.043,20.68a10.8426,10.8426,0,0,1,.8873,8.8828,9.4254,9.4254,0,0,0-.4388-1.4586c-1.1847-3.0254-3.8634-5.36-7.9633-6.9393-2.8187-1.0819-4.618-2.6731-5.3491-4.73C9.2375,13.7848,10.221,10.8942,10.8044,9.5555ZM7.0028,16.2184,4.457,20.68a10.8569,10.8569,0,0,0,0,10.8582,10.6776,10.6776,0,0,0,16.1566,2.935,7.5061,7.5061,0,0,0,.0667-5.2913c-.87-2.1858-2.9646-3.9308-6.2252-5.1876-3.6857-1.4147-6.08-3.6233-7.1157-6.5625A9.297,9.297,0,0,1,7.0028,16.2184Z"
    />
  </svg>
);
