import {useMutation, useQuery} from '@tanstack/react-query';
import {isEmpty} from 'lodash';
import API from '@/hooks/api/useCallAPI';
import {message} from 'antd';

type FetcherParamsT = {};

const fetcher = async (params) => {
  const rs = await API.patch(`/nft-collections/${params.id}`, params);
  return rs;
};

const useEditCollectionsMutation = (
  onSuccess?: ((arg0: any, arg1?: any) => void) | undefined,
  onError?: ((arg0: any, arg1?: any) => void) | undefined
) => {
  const fn = useMutation(['copllectiosdfn', 'create'], {
    mutationFn: (variables: FetcherParamsT) => {
      return fetcher({
        ...variables,
      });
    },
    onSuccess: (rs, vars) => {
      onSuccess && onSuccess(rs, vars);
    },
    onError: (err: any) => {
      message.error(err);
    },
    retry: false,
  });
  return {
    ...fn,
  };
};

export {useEditCollectionsMutation};
