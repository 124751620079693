import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PageTitle from '@/components/pagetitle/PageTitle';
import {Link, useParams, useSearchParams} from 'react-router-dom';

import img1 from '@/assets/images/product/product4.jpg';
import nftTag from '@/assets/images/product/NFT_Tag.png';
import {CopyOutlined} from '@ant-design/icons';
import {useFarmQuery} from '@/hooks/farms/useFarmQuery';
import {useNFTFarmQuery} from '@/hooks/farms/useFarmsQuery';
import Countdown, {zeroPad} from 'react-countdown';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {convertBalance, getObjectById} from '@/utils/SuiObject';
import {DRA_TOKEN, SuiObjectName} from '@/utils/constant';
import {shortAddress} from '@/utils/utils';
import {useNFTsQuery} from '@/hooks';
import {useNFTFarmContext} from '@/contexts/NFTFarmProtocolContext';

NFTFarmDetailPage.propTypes = {};

const TAB_NAME = {
  tab1: ['My Stakes & Rewards', 'Pool Infomation'],
  tab2: ['Available NFTs', 'Staked NFTs'],
};
function NFTFarmDetailPage(props) {
  const [activeTab, setActiveTab] = useState({tab1: TAB_NAME.tab1[0], tab2: TAB_NAME.tab2[0]});
  const params = useParams();
  const {id} = params;
  const {addressWallet, balanceWallet} = useBlockchainContext();
  const {stakingNft} = useNFTFarmContext();
  const {data: nfts} = useNFTsQuery();
  const {data: dataPools} = useNFTFarmQuery();
  const dataPool = dataPools?.find((el) => el.id === id);
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const myNFTs = useMemo(
    () => nfts?.filter((ele) => ele?.nftOwner === addressWallet && ele.collectionId === dataPool?.nftCollectionId),
    [nfts, addressWallet]
  );

  const stakeNFT = async (nftData) => {
    const res = await stakingNft(dataPool?.dataObject?.nftFarmPool, nftData.nftId, [
      nftData?.collectionObject?.dataObject?.ret?.collection?.witnessTypeArg,
      SuiObjectName.DRAUniqueNft,
    ]);
    console.log(res, 'res');
  };

  const getNftInfo = async (nftData) => {
    const res = await getObjectById(nftData);
    console.log('res', res);
  };

  return (
    <div className="nft-farm-detail-page">
      <PageTitle none="none" title="NFT Yield Farms" />
      <div className="tf-container">
        <div className="head-content">
          <div className="content-block">
            <div className="content-title">Stake</div>
            <div className="content-desc">{dataPool?.collectionObject?.name}</div>
          </div>
          <div className="content-block">
            <div className="content-title">Earn</div>
            <div className="content-desc">DRA</div>
          </div>
          <div className="content-block">
            <div className="content-title">TVL</div>
            <div className="content-desc">250 NFTs</div>
          </div>
          <div className="content-block">
            <div className="content-title">Reward Pool</div>
            <div className="content-desc">{dataPool?.tokenReward}</div>
          </div>
          <div className="content-block">
            <div className="content-title">Multiplier</div>
            <div className="content-desc">{dataPool?.multiplier}</div>
          </div>
          <div className="content-block">
            <div className="content-title">Expired In</div>
            <div className="content-desc">
              <CustomCountdown date={addDays(dataPool?.startTime, dataPool?.poolLength)} />
            </div>
          </div>
        </div>
        <Tabs className="tf-tab tab-info">
          <TabList className="menu-tab ">
            {TAB_NAME.tab1.map((el) => (
              <Tab className={`tab-title ${activeTab == el ? 'active' : ''}`}>
                <div>{el}</div>
              </Tab>
            ))}
          </TabList>

          <TabPanel key="detail">
            <div className="tab-details stake-tab row">
              <div className="col-12 col-md-4">
                <div className="account-info block-info">
                  <div className="title-info">My Account</div>
                  <div className="block-content content-1">
                    <div className="content-tit">Account Address</div>
                    <div className="d-flex">
                      <div className="mr-3">{shortAddress(addressWallet)}</div>
                      <CopyOutlined />
                    </div>
                  </div>
                  <div className="block-content content-1">
                    <div className="content-tit">DRA Balance</div>
                    <div className="d-flex">
                      <div className="mr-5">{convertBalance(balanceWallet[SuiObjectName.DRAToken]) || 0}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="staking-info block-info">
                  <div className="title-info">Staking Information</div>
                  <div className="d-flex justify-content-between">
                    <div className="block-content">
                      <div className="content-tit">My Stakes </div>
                      <div>1 NFTs</div>
                    </div>
                    <div className="block-content">
                      <div className="content-tit">Last Claim </div>
                      <div>27/12/2022</div>
                    </div>
                    <div className="block-content mr-4">
                      <div className="content-tit">My Unclaimed Rewards (DRA)</div>
                      <div>98212</div>
                    </div>
                  </div>
                  <div className="tf-button claim-button">Claim Rewards</div>
                </div>
              </div>
            </div>
            <Tabs className="tf-tab tab-nft">
              <TabList className="menu-tab ">
                {TAB_NAME.tab2.map((el) => (
                  <Tab key={el} className={`tab-title ${activeTab == el ? 'active' : ''}`}>
                    <div>{el}</div>
                  </Tab>
                ))}
              </TabList>

              <TabPanel key="detail">
                <div className="tab-avai row">
                  {myNFTs?.map((el) => (
                    <div className="nft-card">
                      <img className="img-card" src={el?.url} /> <div className="title-card">{el?.name}</div>{' '}
                      <div className="tf-button w-100 text-center stake-btn" onClick={() => stakeNFT(el)}>
                        Stake
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="tab-staked row">
                  <div className="nft-card">
                    <img className="img-card" src={nftTag} /> <div className="title-card">Charles03</div>{' '}
                    <div className="tf-button w-100 text-center stake-btn">Unstake</div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <div className="tab-details pool-tab row">
              <div className="col-12">
                <div className="collection-info block-info">
                  <img className="img-col" src={nftTag} />
                  <div>
                    <div className="title-col">{dataPool?.collectionObject?.name}</div>
                    <div className="d-flex">
                      <div className="mr-3">5Dthb...4hiX</div>
                      <CopyOutlined />
                    </div>
                    <div className="d-flex justify-content-between row-desc">
                      <div className="content-block col-8">
                        <div className="content-title">DragonSui Collection Link</div>
                        <div className="content-desc">{shortAddress(dataPool?.collectionObject?.collectionId)}</div>
                      </div>
                      <div className="content-block col-4">
                        <div className="content-title">NFT Supply</div>
                        <div className="content-desc">200</div>
                      </div>
                      {/* <div className="content-block">
                        <div className="content-title">Royalty Fee</div>
                        <div className="content-desc">Praying Mantis Predators</div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="gene-info block-info">
                  <div className="title-info">Staking Information</div>
                  <div className="row">
                    <div className="content-block col-6">
                      <div className="content-title">Pool Contract Address</div>
                      <div className="content-desc d-flex">
                        {' '}
                        <div className="mr-3">5Dthb...4hiX</div>
                        <CopyOutlined />
                      </div>
                    </div>
                    <div className="content-block col-6">
                      <div className="content-title">Multiplier</div>
                      <div className="content-desc">{dataPool?.multiplier}</div>
                    </div>
                    <div className="content-block col-6">
                      <div className="content-title">Royalty Fee</div>
                      <div className="content-desc">200</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="reward-info block-info">
                  <div className="title-info">Reward Token Information</div>
                  <div className="row">
                    <div className="content-block col-6">
                      <div className="content-title">DragonSui Collection Link</div>
                      <div className="content-desc">{shortAddress(dataPool?.collectionObject?.collectionId)}</div>
                    </div>
                    <div className="content-block col-6">
                      <div className="content-title">NFT Supply</div>
                      <div className="content-desc">{dataPool?.collectionObject?.maxSupply}</div>
                    </div>
                    <div className="content-block col-6">
                      <div className="content-title">Royalty Fee</div>
                      <div className="content-desc">{dataPool?.collectionObject?.royaltyFeeBps}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel key="history">
                      <ul className="tab-history">
                        {tabHistory.map((idx) => (
                          <li key={idx.id}>
                            <div className="box-history">
                              <div className="infor">
                                <div className="img">
                                  <img src={idx.avt} alt="Binasea" />
                                </div>
                                <div className="content">
                                  <h6 className="name">
                                    {idx.name} <span>place a bid</span>
                                  </h6>
                                  <p className="time">8 hours ago</p>
                                </div>
                              </div>
                              <div className="price">
                                <p>4.89 ET</p>
                                <span>= $12.245</span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPanel> */}
        </Tabs>
      </div>
    </div>
  );
}

export default NFTFarmDetailPage;

const CustomCountdown = ({date}) => {
  return (
    <Countdown
      date={date}
      daysInHours={false}
      renderer={({days, hours, minutes, seconds, completed}) => {
        if (completed || date === 'N/A') {
          return (
            <div className="sc-product d-flex justify-content-center mx-2 px-3">
              <div className="" style={{fontFamily: 'monospace', fontWeight: 700, fontSize: '24px'}}>
                N/A
              </div>
            </div>
          );
        } else {
          return (
            <div className="d-flex ">
              <div>
                <div className="mr-2">{zeroPad(days)}d</div>
              </div>
              <div>
                <div className="mr-2">{zeroPad(hours)}h</div>
              </div>

              <div>
                <div className="mr-2">{zeroPad(minutes)}m</div>
              </div>
              <div>
                <div className="mr-2">{zeroPad(seconds)}s</div>
              </div>
            </div>
          );
        }
      }}
    />
  );
};
