import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Dropdown} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';

import icon1 from '../../assets/images/icon/rain1.svg';
import icon2 from '../../assets/images/icon/rain2.svg';
import icon3 from '../../assets/images/icon/ethe.svg';
import CardModal from '../layouts/CardModal';
import {shortAddress} from '@/utils/utils';
import {convertBalanceNumber, getObjectById} from '@/utils/SuiObject';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import './style.scss';
import Masonry from 'react-masonry-css';

HotPick4.propTypes = {
  data: PropTypes.array,
};

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};

function HotPick4(props) {
  const [modalShow, setModalShow] = useState(false);
  const {data, authority, avatarImg} = props;
  const {addressWallet} = useBlockchainContext();
  const [launchpadInfo, setLaunchpadInfo] = useState({});

  const [dataTab] = useState([
    {
      id: 1,
      title: 'ITEMS',
      item: 0,
    },
    // {
    //   id: 2,
    //   title: 'ACTIVITY',
    //   item: 3,
    // },
  ]);

  const history = useNavigate();

  useEffect(() => {
    getNftInfo();
  }, [data]);

  const getNftInfo = async () => {
    if (data?.length > 0) {
      const res = await getObjectById(data[0]?.launchpadId);
      console.log('res', res);
      setLaunchpadInfo(res?.data);
    }
  };

  return (
    <section className="tf-section tf-hot-pick tf-filter">
      <div className="tf-container">
        <div className="row ">
          <div className="col-md-12">
            <div className="tf-heading style-3 mb26 wow fadeInUp">
              <h3 className="heading">Hot Sales</h3>
              <p className="sub-heading">The most creative creator - Based on the last 30 days </p>
            </div>
          </div>
          <div className="col-md-12">
            <Tabs>
              <div className="d-flex justify-content-between mb-wr">
                <TabList>
                  {dataTab.map((idx) => (
                    <Tab key={idx.id}>{idx.title}</Tab>
                  ))}
                </TabList>
                {/* <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="">
                    Recently create
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <li>
                        <span>All items</span>
                      </li>
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                      <li className="active">
                        <span>Listed item</span>
                      </li>
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                      <li>
                        <span>Not for sale</span>
                      </li>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>

              {dataTab.map((idx) => (
                <TabPanel key={idx.id}>
                  <div
                  // className="row tf-filter-container wow fadeInUp"
                  >
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                    >
                      {data?.map((idx) => (
                        <div
                          key={idx.id}
                          onClick={() => history(`/nft/${idx?.nftId}`)}
                          // className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                        >
                          <div style={{}} className="sc-product style2">
                            <div className="top">
                              <Link to="/item-details-v1" className="tag">
                                {idx.name}
                              </Link>
                              {/* <div className="wish-list">
                              <Link to="#" className="heart-icon"></Link>
                            </div> */}
                            </div>
                            <div className="bottom">
                              <div className="details-product">
                                <div className="author mt-4">
                                  <div className="avatar rounded-circle">
                                    <img
                                      className="rounded-circle mt-1"
                                      src={idx?.ownerObject?.dataObject?.avatarImg || avatarImg}
                                      alt="images"
                                      style={{width: '40px', height: '40px'}}
                                    />
                                  </div>
                                  <div className="content">
                                    <div className="position">Owner</div>
                                    <div className="name">
                                      {' '}
                                      <Link to="#">{shortAddress(idx?.nftOwner|| authority)}</Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="features">
                              <div className="product-media">
                                <img src={idx.url} alt="nft" />
                              </div>
                              <div className="rain-drop1">
                                <img src={icon1} alt="images" />
                              </div>
                              <div className="rain-drop2">
                                <img src={icon2} alt="images" />
                              </div>
                            </div>
                            <div className="bottom-style2">
                              <div className="price">
                                <div className="icon" style={{marginTop: '10px'}}>
                                  {/* <img src={icon3} alt="images" /> */}
                                  <SUISvg />
                                </div>
                                <div className="content">
                                  <div className="name"> SUI</div>
                                  <div className="cash">
                                    {
                                      idx?.saleIndex || 0
                                    }
                                  </div>
                                </div>
                              </div>
                              {/* {idx?.nftOwner !== addressWallet && (
                                <div className="product-button">
                                  <Link
                                    to="#"
                                    onClick={() => history(`/nft/${idx?.nftId}`)}
                                    data-toggle="modal"
                                    data-target="#popup_bid"
                                    className="tf-button"
                                  >
                                    Purchase
                                  </Link>
                                </div>
                              )} */}
                              {idx?.saleIndex ? (
                                idx?.nftOwner !== addressWallet && authority === idx?.nftOwner && (
                                  <div className="product-button">
                                    <Link
                                      to="#"
                                      onClick={() => history(`/nft/${idx?.nftId}`)}
                                      data-toggle="modal"
                                      data-target="#popup_bid"
                                      className="tf-button"
                                    >
                                      {' '}
                                      Purchase
                                    </Link>
                                  </div>
                                )
                              ) : (
                                <div className="product-button">
                                  <button to="#" className="tf-button" disabled={true}>
                                    {' '}
                                    Not for sale
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Masonry>
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>

        {/* <div className="col-md-12">
          <div className="btn-loadmore wow fadeInUp">
            <Link to="/explore-v1" className="tf-button style-8 loadmore">
              Explore More <i className="fas fa-long-arrow-right"></i>
            </Link>
          </div>
        </div> */}
      </div>

      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
}

export default HotPick4;

const SUISvg = ({color}) => (
  <svg fill="currentColor" viewBox="0 0 26 40" style={{color: '#9448F0', height: '14px', width: '14px'}}>
    <path
      style={{fill: color, fillRule: 'evenodd'}}
      d="M1.8611,33.0541a13.6477,13.6477,0,0,0,23.7778,0,13.89,13.89,0,0,0,0-13.8909L15.1824.8368a1.6444,1.6444,0,0,0-2.8648,0L1.8611,19.1632A13.89,13.89,0,0,0,1.8611,33.0541ZM10.8044,9.5555,13.0338,5.648a.8222.8222,0,0,1,1.4324,0L23.043,20.68a10.8426,10.8426,0,0,1,.8873,8.8828,9.4254,9.4254,0,0,0-.4388-1.4586c-1.1847-3.0254-3.8634-5.36-7.9633-6.9393-2.8187-1.0819-4.618-2.6731-5.3491-4.73C9.2375,13.7848,10.221,10.8942,10.8044,9.5555ZM7.0028,16.2184,4.457,20.68a10.8569,10.8569,0,0,0,0,10.8582,10.6776,10.6776,0,0,0,16.1566,2.935,7.5061,7.5061,0,0,0,.0667-5.2913c-.87-2.1858-2.9646-3.9308-6.2252-5.1876-3.6857-1.4147-6.08-3.6233-7.1157-6.5625A9.297,9.297,0,0,1,7.0028,16.2184Z"
    />
  </svg>
);
