import {useUIContext} from '@/contexts/AppContext';
import {Button, Modal, Input, Form, Switch, Select, Checkbox, message, Upload, Spin} from 'antd';
import {useState} from 'react';
import './style.scss';
import {useRecoilState} from 'recoil';
import {useCollectionsQuery, useCreateCollectionMutation} from '@/hooks';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {uploadFile} from 'react-s3';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {UploadChangeParam} from 'antd/lib/upload';
import {useNavigate} from 'react-router-dom';
import {Buffer} from 'buffer';
import {UploadImage} from '@/components/UploadImage';
import icon5 from '@/assets/images/svg/drap-upload.svg';
import {convertAddress} from '@/utils/utils';
import {useNFTProtocolContext} from '@/contexts/NFTProtocolContext';
import {useEditCollectionsMutation} from '@/hooks/collections/useEditCollectionsMutation';
window.Buffer = window.Buffer || Buffer;

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const {Option} = Select;

let regexp = /^\d+\.?\d{0,6}$/;

export const CreateCollectionModal = ({isModalOpen, handleCancel}) => {
  const [form] = Form.useForm();
  const {addressWallet} = useBlockchainContext();
  const [fileUpload, setFileUpload] = useState({});
  const [inputName, setInputName] = useState('');
  const {createAndListMarket} = useNFTProtocolContext();

  const {mutateAsync: createCollectionMutation, isLoading: isLoadingCreate} = useCreateCollectionMutation();
  const history = useNavigate();

  const validateCreateCollection = (values) => {
    if (!fileUpload?.bannerImg) {
      message.warning('Please upload Collection Banner Image!');
      return false;
    }

    if (!fileUpload?.featuredImg) {
      message.warning('Please upload Collection Square Header!');
      return false;
    }

    if (!fileUpload?.avatarImg) {
      message.warning('Please upload Collection Avatar Image!');
      return false;
    }

    return true;
  };
  const {checkAuth} = useUIContext();
  const {mutateAsync: editCollection} = useEditCollectionsMutation();
  const {data: collections, refetch: fetchCollection} = useCollectionsQuery({});

  const createCollection = async (values) => {
    message.info('Creating Collection');
    const {website, description, symbol, maxSupply, twitter, discord} = values;

    const res = await createCollectionMutation({
      nftYaml: {
        nftType: 'Classic',
      },
      collectionYaml: {
        name: inputName.trim(),
        description: description,
        symbol: symbol.toUpperCase(),
        receiver: addressWallet,
        tags: ['Art'],
        royaltyFeeBps: 100,
        data: JSON.stringify({...fileUpload, website, twitter, discord}),
        authority: addressWallet,
        url: 'https://dragonsui.com/',
      },
      marketplaceYaml: {
        admin: convertAddress(addressWallet),
        receiver: convertAddress(addressWallet),
      },
      listingsYaml: {
        admin: convertAddress(addressWallet),
        receiver: convertAddress(addressWallet),
        markets: [
          {
            marketType: 'fixed_price',
            token: 'sui::sui::SUI',
            price: (values?.price || 0.005) * Math.pow(10, 9),
            isWhitelisted: false,
          },
        ],
      },
    });
    // if (!res?.status) {
    //   message.error('Create collection failed');
    //   return
    // } 
    // const coll = await fetchCollection();

    // const collect = coll.data.find((el) => el.collectionId === res?.data?.collection?.ret?.ids?.collectionId);
    // const resTrs = await createAndListMarket(`${res?.data?.collection?.ret?.ids?.collectionId}::${inputName.trim()}`);
    // const resMarket = await editCollection({dataListAndMarketObject: resTrs, id: collect?.id});
    if (res?.status) {
      message.success('Create collection success');
      handleCancel();
    } else {
      message.error('Create collection failed');
    }
  };

  const isValidWebsite = (url) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
  };

  const {confirm} = Modal;

  const showConfirm = (values) => {
    if (!checkAuth()) return;
    if (!validateCreateCollection(values)) return;
    confirm({
      title: 'Do you want to proceed to create a new collection?',
      onOk() {
        createCollection(values);
      },
      onCancel() {},
    });
  };

  const isValidTelegramId = (accountId) => {
    const regex = new RegExp('(https?://)?(www[.])?(telegram|t).me/([a-zA-Z0-9_-]*)/?$');
    return regex.test(accountId);
  };
  const isValidDiscordId = (accountId) => {
    const regex = new RegExp('(?:https?://)?discord(?:(?:app)?.com/invite|.gg)/?[a-zA-Z0-9]+/?');
    return regex.test(accountId);
  };
  const isValidTwiterId = (accountId) => {
    const regex = new RegExp('(?=.*\\w)[\\w]{1,15}$');
    // const regex = new RegExp('^@(?=.*\\w)[\\w]{1,15}$');
    return regex.test(accountId);
  };

  return (
    <Modal
      className="create-collection-modal add-nft-inner"
      centered
      visible={isModalOpen}
      footer={false}
      onCancel={() => {
        handleCancel();
        form.resetFields();
      }}
      destroyOnClose={true}
    >
      <div className="create-collection-page">
        <h5 className="title-preview">Create New Collection</h5>
        <div className="row">
          <UploadImage
            title={'Collection Main Header'}
            descSize=" Recommended file size is 1920x670 px"
            className="col-12 col-md-4"
            setFileUpload={(data) => setFileUpload({...fileUpload, bannerImg: data?.location})}
            uploadEle={null}
          />
          <UploadImage
            title={'Collection Square Header'}
            descSize=" Recommended file size is 500x500 px"
            className="col-12 col-md-4"
            setFileUpload={(data) => setFileUpload({...fileUpload, featuredImg: data?.location})}
            uploadEle={null}
          />
          <UploadImage
            title={'Collection Avatar Image'}
            descSize="Recommended file size is 500x500 px"
            className="col-12 col-md-4"
            setFileUpload={(data) => setFileUpload({...fileUpload, avatarImg: data?.location})}
            uploadEle={null}
          />
        </div>

        <h6 className="title-preview">Collection Info</h6>
        <Form initialValues={{maxSupply: 1, price: 0.005}} form={form} onFinish={showConfirm} autoComplete="off">
          <div className="container">
            <div className="row">
              <fieldset className="col-12 col-md-4">
                <label>
                  Collection Name<span className="text-red">*</span>
                </label>
                <Form.Item
                  className="form-item"
                  name="name"
                  rules={[{required: true, message: 'Please input Collection Name!'}]}
                >
                  <input
                    type="text"
                    value={inputName}
                    onChange={(e) => {
                      const re = /^[A-Za-z][A-Za-z0-9 ]*$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        form.setFieldsValue({...form.getFieldsValue(), name: e.target.value});
                        setInputName(e.target.value);
                      }
                    }}
                  />
                </Form.Item>
              </fieldset>
              <fieldset className="col-12 col-md-4">
                <label>
                  Collection Symbol<span className="text-red">*</span>
                </label>
                <Form.Item
                  className="form-item"
                  name="symbol"
                  rules={[{required: true, message: 'Please input Collection Symbol!'}]}
                >
                  <input type="text" />
                </Form.Item>
              </fieldset>
              {/* <fieldset className="col-12 col-md-4">
                <label>
                  Max Supply<span className="text-red">*</span>
                </label>

                <Form.Item
                  className="form-item"
                  name="maxSupply"
                  rules={[{required: true, message: 'Please input Max Supply!'}]}
                >
                  <input type={'number'} />
                </Form.Item>
              </fieldset> */}
            </div>
            <div className="row">
              <fieldset className="col-12 col-md-4">
                <label>Website URL</label>
                <Form.Item
                  className="form-item"
                  name="website"
                  rules={[
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (value && !isValidWebsite(value))
                          return Promise.reject(new Error('Please enter your valid URL!'));
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <input type="text" />
                </Form.Item>
              </fieldset>
              <fieldset className="col-12 col-md-4">
                <label>Twitter URL</label>
                <Form.Item
                  className="form-item"
                  name="twitter"
                  rules={[
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (value && !isValidWebsite(value))
                          return Promise.reject(new Error('Please enter your valid URL!'));
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <input type="text" />
                </Form.Item>
              </fieldset>
              <fieldset className="col-12 col-md-4">
                <label>Discord URL</label>

                <Form.Item
                  className="form-item"
                  name="discord"
                  rules={[
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (value && !isValidWebsite(value))
                          return Promise.reject(new Error('Please enter your valid URL!'));
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <input type="text" />
                </Form.Item>
              </fieldset>
            </div>
            <fieldset>
              <label>
                Description <span className="text-red">*</span>
              </label>
              <Form.Item className="form-item" name="description">
                <textarea allowClear className="w-full desc-collection" rows={6} />
              </Form.Item>
            </fieldset>
            {/* <div className="row">
              <fieldset className="col-12 col-md-4">
                <label>
                  Initial NFT Price <span className="text-red">*</span>
                </label>
                <Form.Item
                  className="form-item"
                  name="price"
                  rules={[
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (!regexp.test(value)) return Promise.reject();
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <input onChange={({target}) => {}} type="text" className="" />
                </Form.Item>
              </fieldset>
            </div> */}

            {/* <div className="title-2-modal">Launchpad Info</div>
          <div>
            <div >Market Type</div>
            <Form.Item className="form-item" name="marketType">
              <Select className="custom-select" style={{width: 220, height: 50}}>
                <Option value="fixed_price">Fixed Price</Option>
                <Option value="auction">Auction</Option>
              </Select>
            </Form.Item>
          </div>
          <div>
            <div >Prices</div>
            <Form.Item className="form-item" name="prices">
              <input />
            </Form.Item>
          </div>
          <div className="flex items-center sub-title">
            Whitelists
            <Form.Item className="form-item" name="whitelists">
              <Checkbox className="ml-3"></Checkbox>
            </Form.Item>
          </div> */}
            {/* <div className="grid grid-cols-3 gap-6">
            <div>
              <div className="flex items-center">
                <div className="mb-0 mr-4 title-2-modal">Collect Royal Fee</div>
                <Form.Item name="name">
                  <Switch />
                </Form.Item>
              </div>

              <div>
                <div >Royal Fee (max 5%)</div>{' '}
                <Form.Item name="royalFee">
                  <input type="number" />
                </Form.Item>
              </div>
            </div>
          </div> */}
            {/* <h6 className="title-preview">
              Create new collection you will pay <span className="text-green">150 SUI</span> in fee to dragonsui.com
            </h6> */}
            {/* <Form.Item name="agreeTerm" valuePropName="checked">
              <Checkbox className="text-white">I agree to ArtZero's Terms of Service</Checkbox>
            </Form.Item> */}
            <Form.Item className="mt-16">
              <button className="tf-button active" type="submit">
                Create Collection
              </button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
