import React, {useState, useEffect} from 'react';
import './styles.scss';
import {Link} from 'react-router-dom';

import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/logo_dark.png';

import './styles.scss';
import {message} from 'antd';

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  const [emailSubscribed, setEmailSubscribed] = useState('');

  const onClickHandler = (e) => {
    e.preventDefault();

    const submitPOST = async () => {
      if (!emailSubscribed) return message.error('Please fill your email!');

      const reg =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{3,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!reg.test(String(emailSubscribed).toLowerCase())) {
        return message.error(`${emailSubscribed} is not a valid email address.`);
      }

      try {
        const data = {email: emailSubscribed};

        // eslint-disable-next-line no-undef
        Pageclip.send('zqaVEbuvCPviRe5ho1vL42Drc3GyedOG', 'subscribe', data, function (error, res) {
          if (error) return message.error('Please try again!');

          if (res.data === 'ok') {
            message.success('Thank you. Submit successful!');
            setEmailSubscribed('');
          }
        });
      } catch (error) {
        console.log('error', error);
      }
    };
    submitPOST();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <footer className="footer">
      <div className="tf-container">
        <div className="row">
          <div className="col-xl-6 col-lg-3 col-md-3">
            <div className="widget widget-infor">
              <div className="logo" style={{maxWidth: '315px'}}>
                <img id="logo_footer" className="logo-dark" src={logodark} alt="" />
                <img id="logo_footer" className="logo-light" src={logo} alt="" />
              </div>
              <p className="content">
                The leading NFT Marketplace on Sui Blockchain Home to the next generation of digital creators. Discover
                the best NFT collections.
              </p>
              <ul className="social-item">
                <li>
                  <a href="https://twitter.com/dragonsui_com" rel="noreferrer" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>

                <li>
                  <a href="https://t.me/dragon_sui" rel="noreferrer" target="_blank">
                    <i className="fab fa-telegram-plane"></i>
                  </a>
                </li>

                <li>
                  <a href="https://discord.gg/BrFMqdExqA" rel="noreferrer" target="_blank">
                    <i className="icon-fl-vt"></i>
                  </a>
                </li>
              </ul>
              <p className="copy-right">Copyright © 2022 DragonSUI. All Rights Reserved.</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-5">
            {/* <div className="widget widget-menu">
              <div className="menu menu-1">
                <h6 className="widget-title">Maketplace</h6>
                <ul>
                  <li>
                    <Link to="/explore-v1">Explore</Link>
                  </li>
                  <li>
                    <Link to="/item-details-v1">Item Detail</Link>
                  </li>
                  <li>
                    <Link to="/live-auctions-v1">Live Auditon</Link>
                  </li>
                </ul>
              </div>
              <div className="menu menu-2">
                <h6 className="widget-title">Stats</h6>
                <ul>
                  <li>
                    <Link to="/ranking">Ranking</Link>
                  </li>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/create">Create</Link>
                  </li>
                </ul>
              </div>
              <div className="menu menu-3">
                <h6 className="widget-title">Resoure</h6>
                <ul>
                  <li>
                    <Link to="/blog-v1">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/help-center">Help and Center</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FaQs</Link>
                  </li>
                </ul>
              </div>
              <div className="menu menu-4">
                <h6 className="widget-title">My account</h6>
                <ul>
                  <li>
                    <Link to="/dashboard">Profile</Link>
                  </li>
                  <li>
                    <Link to="/dashboard">My wallet</Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4">
            <div className="widget widget-subcribe">
              <h6 className="widget-title">Subscribe Us</h6>
              <p className="content">Signup for our newsletter to get the latest news in your inbox.</p>
              <form id="subscribe-form" onSubmit={onClickHandler} className="pageclip-form">
                <input
                  type="email"
                  name="email"
                  placeholder="Info@yourgmail.com"
                  required=""
                  id="subscribe-email"
                  value={emailSubscribed}
                  onChange={({target}) => setEmailSubscribed(target.value)}
                />
                <button className="tf-button button pageclip-form__submit" type="submit" id="subscribe-button">
                  <i className="icon-fl-send"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </footer>
  );
}

export default Footer;
