import {useQuery} from '@tanstack/react-query';
import {isEmpty} from 'lodash';
import API from '@/hooks/api/useCallAPI';

type FetcherParamsT = {};

const fetcher = async () => {
  const rs = await API.get('/nfts');
  return rs.data;
};

const useNFTsQuery = () => {
  const fn = useQuery(
    ['NFTs'],
    () => {
      return fetcher();
    },
    {
      refetchOnWindowFocus: true,
      retry: false,
    }
  );
  return {
    ...fn,
  };
};

export {useNFTsQuery};
