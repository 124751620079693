import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PageTitle from '@/components/pagetitle/PageTitle';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';

import img1 from '@/assets/images/product/product4.jpg';
import nftTag from '@/assets/images/product/NFT_Tag.png';
import {CopyOutlined} from '@ant-design/icons';
import {useFarmQuery} from '@/hooks/farms/useFarmQuery';
import {useNFTFarmQuery} from '@/hooks/farms/useFarmsQuery';
import Countdown, {zeroPad} from 'react-countdown';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {convertBalance, getObjectById} from '@/utils/SuiObject';
import {DRA_TOKEN, SuiObjectName} from '@/utils/constant';
import {shortAddress} from '@/utils/utils';
import {useNFTsQuery} from '@/hooks';
import {useNFTFarmContext} from '@/contexts/NFTFarmProtocolContext';

LaunchpadPage.propTypes = {};

function LaunchpadPage(props) {

  const navigate = useNavigate();


  return (
    <div className="launchpad-page">
      <PageTitle none="none" title="Launchpad" />
      <div className="tf-container mt-5">
        <div className="info-block">
          <div className='d-flex justify-content-between'>
            <h5>Live projects</h5>
            <div className="tf-button" onClick={() => navigate('/create-launchpad')}>Create Project</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaunchpadPage;
