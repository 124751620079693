

import img1 from '../images/svg/icon-create-1.svg'
import img2 from '../images/svg/icon-create-2.svg'
import img3 from '../images/svg/icon-create-3.svg'
import img4 from '../images/svg/icon-create-4.svg'

const dataCreate = [
    {
        id: 1,
        img: img1,
        heading: 'Connect your wallet',
        text: 'DragonSUI supports SUI Wallet (Sui Wallet Browser Chrome Extension)'
    },
    {
        id: 2,
        img: img2,
        heading: 'Create Your collection',
        text: 'Add social links, a description, profile and banner images, and set a secondary sales fee'
    },
    {
        id: 3,
        img: img3,
        heading: 'Create your NFTs',
        text: 'Upload your work (image, video, audio, or 3D art),a title description, and customize your NFTs with properties, stats, & unlockable content.'
    },
    {
        id: 4,
        img: img4,
        heading: 'List NFTs for sale',
        text: 'Choose the NFT you want to sell and we help you sell them. buyers can bid for the NFT or simply buy at fixed-price'
    },


]

export default dataCreate;