import { message } from "antd";

const menus = [
  // {
  //   id: 1,
  //   name: 'Home',
  //   links: '#',
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: 'Home 01',
  //       links: '/',
  //     },
  //   ],
  // },
//   {
//     id: 2,
//     name: 'Explore',
//     links: '#',
//     namesub: [
//       {
//         id: 1,
//         sub: 'Explore v1',
//         links: '/explore-v1',
//       },
//       {
//         id: 2,
//         sub: 'Explore v2',
//         links: '/explore-v2',
//       },
//       {
//         id: 3,
//         sub: 'Explore v3',
//         links: '/explore-v3',
//       },
//       {
//         id: 4,
//         sub: 'Explore v4',
//         links: '/explore-v4',
//       },
//       {
//         id: 5,
//         sub: 'Collection',
//         links: '/collection',
//       },
//       {
//         id: 6,
//         sub: 'Live Auction v1',
//         links: '/live-auctions-v1',
//       },
//       {
//         id: 7,
//         sub: 'Live Auction v2',
//         links: '/live-auctions-v2',
//       },
//       {
//         id: 8,
//         sub: 'Item Details v1',
//         links: '/item-details-v1',
//       },
//       {
//         id: 9,
//         sub: 'Item Details v2',
//         links: '/item-details-v2',
//       },
//     ],
//   },
//   {
//     id: 4,
//     name: 'Pages',
//     links: '#',
//     namesub: [
//       {
//         id: 1,
//         sub: 'Dashboard ',
//         links: '/dashboard ',
//       },
//       {
//         id: 2,
//         sub: 'Ranking',
//         links: '/ranking',
//       },
//       {
//         id: 3,
//         sub: 'Help Center',
//         links: '/help-center',
//       },
//       {
//         id: 4,
//         sub: 'FAQ',
//         links: '/faqs',
//       },
//       {
//         id: 5,
//         sub: 'Connect Wallet',
//         links: '/wallet',
//       },
//       {
//         id: 6,
//         sub: 'Login',
//         links: '/login',
//       },
//       {
//         id: 7,
//         sub: 'Sign Up',
//         links: '/signup',
//       },
//     ],
//   },

{
    id: 4,
    name: 'Marketplace',
    links: '/',
  },  
{
    id: 5,
    name: 'Create',
    links: '/create-nft',
  },
//   {
//     id: 6,
//     name: 'Blog',
//     links: '#',

//     namesub: [
//       {
//         id: 1,
//         sub: 'Blog v1',
//         links: '/blog-v1',
//       },
//       {
//         id: 2,
//         sub: 'Blog v2',
//         links: '/blog-v2',
//       },
//       {
//         id: 3,
//         sub: 'Blog Details v1',
//         links: '/blog-details-v1',
//       },
//       {
//         id: 4,
//         sub: 'Blog Details v2',
//         links: '/blog-details-v2',
//       },
//     ],
//   },
  {
    id: 7,
    name: 'Staking',
    links: '/staking',
  },
  {
      name: 'Public sale',
      links: '/public-sale',
  },
  {
    id: 9,
    name: 'NFT Staking',
    eventClick: () => message.info('Coming soon!'),
  },
  {
    id: 9,
    name: 'Launchpad',
    eventClick: () => message.info('Coming soon!'),
  },
//   {
//     id: 9,
//     name: 'Contact',
//     links: '/contact',
//   },
];

export default menus;
